import React, { useRef, useEffect,useState } from 'react';
import { Card, CardContent ,Box,FormControlLabel,Checkbox ,Typography,Grid } from '@mui/material';
import { styled } from '@mui/system';
import { CardHeader } from '@mui/material';



const ScrollableCardPhone  = ({phoneData, setPhoneData,listData,inputData ,setListData,mobilePhone}) => {
  const cardRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false); 
  const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
  const [inputErrors, setInputErrors] = useState([]);
  // const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก

  useEffect(() => {
    // console.log(inputData)
    const selectedIndexList = listData.map(item => item.index); // ดึง index จาก listData
    setSelectedIndexes(selectedIndexList);
    // console.log("cardRef.current:", cardRef.current);

    
  }, [phoneData]); // เรียกครั้งเดียวหลัง 
  
  useEffect(() => {
      // console.log(inputData)
    const updatedList = listData.map((item) => ({
      ...item,
      policy_no: item.policy_no,
      mobile: inputData.mobile?.[0] ?? "",
      phone_home: inputData.phone_home?.[0] ?? "",
      phone_office: inputData.phone_office?.[0] ?? "",
      phone_home_to: inputData.phone_home?.[1] ?? "",
      phone_office_to: inputData.phone_office?.[1] ?? "",
      phone_other: inputData.phone_other ?? "",

    }));
    setListData(updatedList);
  }, [inputData]);


  const CustomCheckbox = styled(Checkbox)({
    color: 'rgba(255, 94, 67, 0.5)',
    '&.Mui-checked': {
      color: '#FF5E43',
    },
    marginRight: '15px'
  });

  

  
  
  const handleBoxClick = (index, policy_no, old_mobile, old_phone_office, old_phone_office_to, old_phone_home, old_phone_home_to, old_phone_other) => {
    setSelectedIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        // ถ้าถูกเลือกแล้ว ให้ลบออกจาก array
        // console.log(policy_no)
        removeItemByPolicyNo(policy_no);
        return prevIndexes.filter(i => i !== index);
      } else {
        // ถ้ายังไม่ถูกเลือก ให้เพิ่มเข้าไปใน array
        const processData = inputData || {};
        const updatedList = {
          index:index,
          policy_no: policy_no,
          mobile: processData.mobile ?? "",
          phone_home: processData.phone_home?.[0] ?? "",
          phone_office: processData.phone_office?.[0] ?? "",
          phone_home_to: processData.phone_home?.[1] ?? "",
          phone_office_to: processData.phone_office?.[1] ?? "",
          phone_other: processData.phone_other ?? "",

          old_mobile: old_mobile,
          old_phone_office: old_phone_office,
          old_phone_home: old_phone_home,
          old_phone_office_to: old_phone_office_to,
          old_phone_home_to: old_phone_home_to,
          old_phone_other: old_phone_other,
        }
    // console.log(updatedList)

        addItem(updatedList);
        return [...prevIndexes, index];
      }
    });

  };

  const addItem = (newItem) => {
    setListData((prevListData) => [...prevListData, newItem]);
  };

  const removeItemByPolicyNo = (policyNo) => {
    setListData((prevListData) => prevListData.filter(item => item.policy_no !== policyNo));
  };

  const removeAllItems = () => {
    setListData([]); // ตั้งค่า listData ให้เป็น array ว่าง
    setSelectedIndexes([]); // เคลียร์ selectedIndexes
  };

  const handleCheckAll = () => {
    if (isChecked) {
      removeAllItems(); // ลบข้อมูลทั้งหมด
    } else {
      const allIndexes = phoneData.map((_, index) => index); // สร้าง array ของทุก index
      setSelectedIndexes(allIndexes);
      //  console.log(phoneData)
       const processData = inputData || {};
      const updatedList = phoneData.map((item,i) => ({
        policy_no: item.policy_no,
        index:i,
        policy_number: item.id,
        mobile: mobilePhone,
        mobile: processData.mobile ?? "",
        phone_home: processData.phone_home?.[0] ?? "",
        phone_office: processData.phone_office?.[0] ?? "",
        phone_home_to: processData.phone_home?.[1] ?? "",
        phone_office_to: processData.phone_office?.[1] ?? "",
        phone_other: processData.phone_other ?? "",

        old_mobile: item.mobile,
        old_phone_office: item.phone_office,
        old_phone_home: item.phone_home,
        old_phone_office_to: item.phone_office_to,
        old_phone_home_to: item.phone_home_to,
        old_phone_other: item.phone_other,
      }));



      setListData(updatedList);
    }
    setIsChecked(!isChecked); // เปลี่ยนสถานะ CheckAll
  };

  return (
    <Card  ref={cardRef} sx={{ maxHeight: '490px', overflowY: 'auto',borderRadius: '12px' }}>
        <CardContent
          sx={{
            maxHeight: '490px',
            overflowY: 'auto',
            border: '1px solid rgba(255, 94, 67, 0.3)', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
            borderRadius: '12px', // ความโค้งของขอบ
            borderWidth: '2px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
          <Box>
            <FormControlLabel
              control={<CustomCheckbox onClick={handleCheckAll} checked={isChecked} />}
              label={<Typography style={{ fontSize: '20px' }}>ทุกกรมธรรม์</Typography>}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{ color: 'red', marginTop: '5px', marginLeft: '5px' }} // ข้อความสีแดงพร้อม margin ด้านบน
          >
            *กรุณาเลือกกรมธรรม์
          </Typography>
          {phoneData.map((_, index) => (

            <Box
              key={index}
              onClick={() => handleBoxClick(index, _.policy_no, _.mobile, _.phone_office, _.phone_office_to, _.phone_home, _.phone_home_to, _.phone_other)}
              sx={{
                marginBottom: '15px',
                padding: '10px',
                transition: 'background-color 0.3s ease, border 0.3s ease',
                cursor: 'pointer',
                alignItems: 'center', // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
                justifyContent: 'space-between', // แยกข้อความทางซ้ายกับไอคอนทางขวา
              }}
            >
              <Card style={{ zIndex: 1000 }} sx={{

                backgroundColor: selectedIndexes.includes(index)
                  ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
                  : 'transparent', // พื้นหลังปกติ
                transition: 'background-color 0.3s ease, border 0.3s ease',
              }}>
                <CardHeader
                  title={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CustomCheckbox
                        checked={selectedIndexes.includes(index)} // ทำการเช็คตาม selectedIndexes
                        onChange={(e) => {
                          // console.log("");
                          e.stopPropagation();
                        }}
                        sx={{ color: 'rgba(255, 94, 67, 0.8)' }} // ปรับสี
                      />
                      <Box sx={{ marginLeft: '10px' }}> {/* เพิ่มระยะห่างระหว่าง checkbox และข้อความ */}
                        {"กรมธรรม์เลขที่ : " + _.policy_no}
                      </Box>
                    </Box>
                  }
                  sx={{
                    backgroundColor: 'rgba(255, 94, 67, 0.3)', // ปรับให้ใช้งาน rgba ถูกต้อง
                  }}
                  titleTypographyProps={{
                    sx: {
                      fontFamily: '"Prompt-Thin", sans-serif !important',
                      fontSize: '15px',
                      color: 'black',
                      marginLeft: '15px',
                    },
                  }}
                />
                <CardContent>
                  <Typography  >
                    <Grid container spacing={1} >
                      <Grid item xs={7} sm={4} md={2} lg={3} xl={3}>
                        <span className="font-size-label-pol">เบอร์โทรศัพท์มือถือ :</span>
                      </Grid>
                      <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                        <span className="font-size-label-pol">
                          {`${_.mobile == "" ? "-" : _.mobile} `}
                        </span>
                      </Grid>

                    </Grid>

                    <Grid container spacing={1} >
                      <Grid item xs={7} sm={4} md={2} lg={3} xl={3}>
                        <span className="font-size-label-pol">เบอร์โทรศัพท์บ้าน :</span>
                      </Grid>
                      <Grid item xs={3} sm={3} md={2} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {`${_.phone_home != "" ? `${_.phone_home}` : "-"} `}  {`${_.phone_home_to != "" && _.phone_home_to != "-" ? `ต่อ ${_.phone_home_to}` : ""} `}
                        </span>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>

                      </Grid>
                    </Grid>


                    <Grid container spacing={1} >
                      <Grid item xs={8} sm={4} md={4} lg={3} xl={3}>
                        <span className="font-size-label-pol">เบอร์โทรศัพท์ที่ทำงาน :</span>
                      </Grid>
                      <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                        <span className="font-size-label-pol">
                          {`${_.phone_office != "" ? `${_.phone_office}` : "-"} `}  {`${_.phone_office_to != ""&& _.phone_office_to != "-" ? `ต่อ ${_.phone_office_to}` : ""} `}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} >
                      <Grid item xs={7} sm={4} md={2} lg={3} xl={3}>
                        <span className="font-size-label-pol">เบอร์โทรศัพท์อื่นๆ :</span>
                      </Grid>
                      <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                        <span className="font-size-label-pol">
                          {`${_.phone_other =="" ? "-" : _.phone_other} `}
                        </span>
                      </Grid>
                    </Grid>

                  </Typography>
                </CardContent>
              </Card >
              <Box >
              </Box>
            </Box>


          ))}
        </CardContent>

    </Card>
  );
};

export default ScrollableCardPhone;
