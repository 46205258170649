import { Axios } from "../../../util";


const getPolicyDetailAPI = async (policyNo, insureIdCard) => {
  try {
    const res = await Axios.post(`/api/policy/policyDetail?policyNo=${policyNo}&insureIdCard=${insureIdCard}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getParameterAPI = async (pmt_id) => {
  try {
    const res = await Axios.get(
      `api/parameter/getparameterdetatail?pmt_Id=${pmt_id}`,
      {
        headers: { accept: "application/json" },
      }
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};


export { getPolicyDetailAPI,
         getParameterAPI };