import React, { useState, useEffect, useContext } from "react";
import { Col } from "react-bootstrap";
import { TextField, InputLabel } from "@mui/material";
import { requestOtpAPI } from "../RefundService/refundService";
import { toast, ToastContainer } from "react-toastify";
import { StoreContext } from "../../../context/store";

const VerifyForm = ({ channelType }) => {
  const [otp, setOtp] = useState("");
  const { updateInformationEditBank, updateInformationEditPromptPay } =
    useContext(StoreContext);

  const handleOtp = (e) => {
    let otp = e.target.value;
    setOtp(otp);
    let data = {
      otp: otp,
    };
    if (channelType === "bank") {
      updateInformationEditBank(data);
    } else {
      updateInformationEditPromptPay(data);
    }
  };

  const resendOtp = () => {
    handleReqOtp();
  };

  const notifySuccess = (title) => {
    toast.success(title, {
      position: "top-center",
    });
  };

  const notifyError = (title) => {
    toast.error(title, {
      position: "top-center",
    });
  };

  const handleReqOtp = async () => {
    const resultReqOtp = await requestOtpAPI();
    if (resultReqOtp.status === true) {
      notifySuccess(resultReqOtp.meg);
    } else {
      notifyError(resultReqOtp.meg);
    }
  };

  useEffect(() => {
    handleReqOtp();
  }, []);

  return (
    <>
      <label htmlFor="otp">
        กรุณาใส่หมายเลข OTP 6 หลัก ที่ส่งไปยังเบอร์มือถือของท่าน
        (รหัสนี้มีอายุการใช้งาน 5 นาที)
      </label>
      <TextField
      id="otp"
      type="tel"
      className="mb-2"
      placeholder="OTP"
      variant="outlined"
      size="small"
      onChange={(e) => handleOtp(e)}
      inputProps={{
        maxLength: 6,
        inputMode: "numeric",
        pattern: "[0-9]*",
        onInput: (e) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
        }
      }}
      fullWidth
    />

      <small
        id="emailHelp"
        onClick={resendOtp}
        className="form-text text-primary pointer"
        style={{ cursor: 'pointer' }}
      >
        ส่งรหัส OTP อีกครั้ง?
      </small>

      <ToastContainer />
    </>
  );
};

export default VerifyForm;
