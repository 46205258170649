import React, { useContext, useState, useEffect } from "react";
import TransferContext, {
  TransferProvider,
} from "../TranferContextProvider.js";
import uploadImg from "../../../images/upload.svg";
import {
  getFileUploadList,
  uplaodFile,
} from "../quickTransferServices/QuickTransferServices.js";
import { notifyWarning } from "../../../util/Notification.js";
import Skeleton from "react-loading-skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const UploadFilePolicy = () => {
  const [listUplaod, setListUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [fileNameList , setFileNameList] = useState([]);

  const {
    activeStep,
    setActiveStep,
    skipped,
    setSkipped,
    titleScreen,
    docRange,
    uuid,
    citizenId,
  } = useContext(TransferContext);

  useEffect(() => {
    // alert(uuid)
    setTimeout(() => {
      setLoading(false);
    }, 500);
    initialFileUploadList();
    setLoading(true);
  }, [activeStep]);

  const initialFileUploadList = async () => {
    const res = await getFileUploadList(docRange);
    if (res.success == true) {
      setListUpload(res.data);
      const updatedFileNameList = listUplaod.map((file) => {
        return "";  
      });
      setFileNameList(updatedFileNameList);
    }
  };

  const uploadQuickFile = async () => {
    try {
      const formData = new FormData();

      listUplaod.forEach((item) => {
        if (item.hasOwnProperty("fileContent")) {
          formData.append("files", item.fileContent, item.fileContent.name);
          formData.append("codes", item.code);
        } else {
          if (item.requir == "Y") {
            throw err;
          }
        }
      });
      formData.append("uuid", uuid);
      formData.append("ageRange", docRange);
      formData.append("citizenId", citizenId);
      setLoadingUploadFile(true)
      const res = await uplaodFile(formData);
      setLoadingUploadFile(false)
      handleNext()
    } catch (err) {
      notifyWarning("กรุณาแนบไฟล์ให้ครบ");
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handlerFile = (index, e, fileType) => {
    // console.log("index", index);
    // console.log("fileType", fileType);
    listUplaod[index]["fileContent"] = e.target.files[0];

    const updatedList = [...fileNameList];
    updatedList[index] = e.target.files[0].name;
    setFileNameList(updatedList);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingUploadFile}
      >
        <CircularProgress
          sx={{
            animationDuration: "550ms",
          }}
          color="inherit"
        />
      </Backdrop>
      <div className="border-content">
        <div className="title-font">
          {"นำส่งเอกสารแสดงตัวตน " + titleScreen}
        </div>
        <svg height="10" width="100%" style={{ marginTop: 15 }}>
          <line
            x1="0"
            y1="5"
            x2="100%"
            y2="5"
            style={{ stroke: "black", strokeWidth: 0.5 }}
          />
        </svg>
        <div style={{ marginTop: 15 }}>
          {loading ? (
            <div className="p-1">
              <div className="p-1">
                <Skeleton className="h-70px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
              </div>
            </div>
          ) : (
            <>
              {listUplaod.map((item, index) => (
                <div key={item.id} className="render-file-detail">
                  <div>
                    <span className="consent-font-size">{item.fileType}</span>
                    {item.requir == "Y" && (
                      <span className="text-danger">*</span>
                    )}
                    {fileNameList[item.id] != '' ? 
                       <div className="title-file-font" style={{marginLeft:10}}>{fileNameList[item.id]}</div>
                    : "" }
                  </div>

                  <label
                    htmlFor={"file" + item.id}
                    className="btn-upload-file font-size-content-upload d-flex flex-row justify-content-center align-items-center"
                  >
                    <i style={{ fontSize: 14 }}></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                    <input
                      type="file"
                      id={"file" + item.id}
                      key={"file" + item.id}
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      style={{ display: "none" }}
                      onChange={(e) => handlerFile(item.id, e, item.fileType)}
                    />
                  </label>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="button-contain">
        <button
          style={{ fontSize: 15 }}
          onClick={uploadQuickFile}
          className="btn-checkout-premium w-100 p-2 bold"
        >
          ตกลง
        </button>
        <button
          className="btn-checkout-back w-100 p-2 bold"
          onClick={handleBack}
        >
          กลับ
        </button>
      </div>
    </>
  );
};

export default UploadFilePolicy;
