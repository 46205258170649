import React, { Fragment, useState, useEffect } from "react";
import Header_Home from "../Component/Header_Home";
import CollapseTable from "../RefundPolicy/collapseTable.jsx";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getPolicyCouponAPI } from "./RefundService/refundService.js";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Header from "../Component/Header";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { CurrencyFormater } from "../../util/function.js";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import {
  Grid
} from "@mui/material";
const RefundPolicy = () => {
  const [activeTab, setActiveTab] = useState();
  const [dataCoupon, setDataCoupon] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const dataParams = location.state || {};
  const [cusTel, setCusTel] = useState("");

  const handleTooltip = () => {
    setOpen(!open);
  };

  // const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const policyNo = dataParams.policyNo || "";

  useEffect(() => {
    getDataRefundPolicy();
    getTel();
  }, []);

  const getDataRefundPolicy = async () => {
    const result = await getPolicyCouponAPI(policyNo);
    if (result.status) {
      setDataCoupon(result.data);
      setDataLoading(false);
    }
    // console.log(result);
  };

  const goToSendDoc = () => {
    // window.location.href = "/sendDoc?policyNo=" + policyNo + "&age=" + dataCoupon.insureAge;
    if (dataCoupon.policyIdnvFlag == "Y") {
      navigate(`/channelPay`, {
        state: {
          policyNo: policyNo,
          age: dataCoupon.insureAge,
        },
      });
    } else if (
      dataCoupon.policyIdnvFlag == "N" &&
      dataCoupon.policyIServiceIdnvFlag == "N"
    ) {
      navigate(`/sendDoc`, {
        state: {
          policyNo: policyNo,
          age: dataCoupon.insureAge,
        },
      });
    } else if (
      dataCoupon.policyIdnvFlag == "N" &&
      dataCoupon.policyIServiceIdnvFlag == "Y"
    ) {
      navigate(`/channelPay`, {
        state: {
          policyNo: policyNo,
          age: dataCoupon.insureAge,
        },
      });
    }
  };

  const goBack = () => {
    navigate(`/policylist?policyNo=${policyNo}`);
  };

  const handleSelect = (key) => {
    setActiveTab(key);
    // console.log(key);
  };

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    const variable1 = cusTel.variable1;
    setCusTel(variable1);
  };
  const maskAccountNo = (acc) => {
    if (acc.length === 10) {
      return acc.replace(/(\d{4})(\d{3})(\d{3})/, "$1xxx$3");
    } else if (acc.length > 10) {
      return acc.replace(
        /(\d{4})(\d+)(\d{1})/,
        "$1" + "x".repeat(acc.length - 5) + "$3"
      );
    }
    return acc;
  };

  return (
    <Fragment>
      <Header_Home />
      <Header title="เงินคืนตามเงื่อนไขกรมธรรม์" />
      <div className="containers">
        {dataLoading ? (
          <Row className="d-flex justify-content-around px-3">
            <Col>
              <Skeleton className="text-loading" />
            </Col>
          </Row>
        ) : (
          <h5 style={{ fontWeight: "bold" }}>เงินผลประโยชน์</h5>
        )}

        {dataLoading ? (
          <Row className="card-custom-loading d-flex">
            <Col className="mt-3">
              <Skeleton style={{ height: "30px" }} />
              <div className="d-flex flex-row justify-content-between">
                <Skeleton />
              </div>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Col>
          </Row>
        ) : (
          <Tabs
            defaultActiveKey="home"
            id="fill-tab-example"
            // className="mb-3"
            fill
            activeKey={activeTab}
            onSelect={handleSelect}
          >
            <Tab eventKey="home" title="จำนวนเงินผลประโยชน์">
              <div style={{ paddingLeft: 0 }} className="card-custom col-12">
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={3}>
                    <span className="light">ปีที่ :</span>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                    <span className="bold">{dataCoupon.couponYear || "-"}</span>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <span className="light">ประเภทเงิน :</span>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                    <span className="bold">{dataCoupon.couponType || "-"}</span>
                  </Grid>

                  {dataCoupon.couponLoanAmount == 0 ? (
                    <>
                      <Grid item xs={6} sm={3}>
                        <span className="light">เงินจ่ายคืน :</span>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                        <div>
                          <span className="bold">
                            {CurrencyFormater(dataCoupon.couponPaymentAmount)} <strong>บาท</strong>
                          </span>
                          <span className="bold" style={{ display: "block", color: "red", fontSize: "12px" }}>
                            *จำนวนเงินก่อนหักหนี้สิน (ถ้ามี)
                          </span>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={6} sm={3}>
                        <span className="light">หักหนี้สิน :</span>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                        <span className="bold">
                          {CurrencyFormater(dataCoupon.couponLoanAmount)} <strong>บาท</strong>
                        </span>
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <span className="light">คงเหลือสุทธิ :</span>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                        <span className="bold">
                          {dataCoupon.couponPaymentAmount == 0 ? "-" : `${CurrencyFormater(dataCoupon.couponPaymentAmount)} บาท`}
                        </span>
                      </Grid>
                    </>
                  )}

                  {dataCoupon.couponPaymentAmount !== 0 && (
                    <>
                      <Grid item xs={6} sm={3}>
                        <span className="light">วันที่รับเงิน :</span>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                        <span className="bold">{dataCoupon.couponPaymentDate || "-"}</span>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={8} sm={3}>
                    <span
                      className="light"
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        // alignItems: "center",
                      }}
                    >
                      วันที่ครบกำหนดรับเงิน
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltip}
                        onClick={handleTooltip}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="ขอแนะนำให้ท่านใช้ บัญชีธนาคารกรุงเทพ  /  กสิกรไทย /  ไทยพาณิชย์ เพื่อได้รับเงินโอนภายในวันที่ครบกำหนดจ่ายฯ ยกเว้นในกรณีที่กรมธรรม์มีหนี้สิน ท่านจะได้รับเงินภายใน 10 วัน "
                        placement="top-end"
                        sx={{ color: "rgba(255, 94, 67, 1.0)" }}
                      >
                        <IconButton style={{ marginTop: "-10px" }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      :
                    </span>
                  </Grid>
                  <Grid item xs={4} sm={3} style={{ textAlign: "right" }}>
                    <span className="bold">{dataCoupon.couponDueDate || "-"}</span>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <span className="light">ช่องทางการรับเงิน :</span>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                    {dataCoupon.policyIServicePaymentFlag == "Y" && dataCoupon.couponPaymentType === "" ? (
                      <span
                        className="warning-word"
                        style={{ textAlign: "end" }}
                      // className="bold text-danger"
                      >
                        {dataCoupon.policyIServicePaymentMeg}
                      </span>
                    ) : dataCoupon.policyIServicePaymentFlag == "N" && dataCoupon.couponPaymentType === "" ? (
                      <span className="warning-word">ท่านยังไม่ได้เพิ่มบัญชีธนาคารเพื่อรับเงินคืน</span>
                    ) : (
                      <span className="warning-word">{dataCoupon.couponPaymentType}</span>
                    )}
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <span className="light">ธนาคาร :</span>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                    <span className="bold">{dataCoupon.couponBankName || "-"}</span>
                  </Grid>

                  <Grid item xs={7} sm={3}>
                    <span className="light">เลขที่บัญชี / เลขที่เช็ค :</span>
                  </Grid>
                  <Grid item xs={5} sm={3} style={{ textAlign: "right" }}>
                    <span className="bold">
                      {dataCoupon.couponBankAccount ? maskAccountNo(dataCoupon.couponBankAccount) : "-"}
                    </span>
                  </Grid>

                  {dataCoupon.policyIdnvFlag == "N" && (
                    <Grid item xs={12}>
                      <div className="light warning-word">
                        ** {dataCoupon.policyIServiceIdnvFlag === "Y" ? dataCoupon.policyIServiceIdnvMeg : "ท่านยังไม่แนบเอกสารแสดงตน"}
                      </div>
                    </Grid>
                  )}
                </Grid>

                {/* <ul
                  className="list-group list-group-flush"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <li
                    className="body-list-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // marginRight: "10px",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    <span className="light">ปีที่ :</span>
                    <span className="bold">{dataCoupon.couponYear}</span>
                  </li>
                  <li
                    className="body-list-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // marginRight: "10px",
                      fontSize: "15px",
                    }}
                  >
                    <span className="light">ประเภทเงิน :</span>
                    <span className="bold">{dataCoupon.couponType}</span>
                  </li>
                  {dataCoupon.couponLoanAmount == 0 ? (
                    <li
                      className="body-list-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // marginRight: "10px",
                        fontSize: "15px",
                      }}
                    >
                      <span className="light">เงินจ่ายคืน :</span>
                      <div style={{ textAlign: "right" }}>
                        <span className="bold">
                          {CurrencyFormater(dataCoupon.couponPaymentAmount)}
                          <strong> บาท</strong>
                        </span>
                        <span className="bold" style={{ display: "block" }}>
                          <strong style={{color:"red",fontSize:"13px"}}> *จำนวนเงินก่อนหักหนี้สิน (ถ้ามี)</strong>
                        </span>
                      </div>
                    </li>
                  ) : (
                    <>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // marginRight: "10px",
                          fontSize: "15px",
                        }}
                      >
                        <span className="light">หักหนี้สิน :</span>
                        <span className="bold">
                          {CurrencyFormater(dataCoupon.couponLoanAmount)}
                          <strong> บาท</strong>
                        </span>
                      </li>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // marginRight: "10px",
                          fontSize: "15px",
                        }}
                      >
                        <span className="light">คงเหลือสุทธิ :</span>
                        <span className="bold">
                          {dataCoupon.couponPaymentAmount == 0 ? (
                            "-"
                          ) : (
                            <>
                              {CurrencyFormater(dataCoupon.couponPaymentAmount)}
                              <strong> บาท</strong>
                            </>
                          )}
                        </span>
                      </li>
                    </>
                  )}
                  {dataCoupon.couponPaymentAmount !== 0 && (
                    <>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // marginRight: "10px",
                          fontSize: "15px",
                        }}
                      >
                        <span className="light">วันที่รับเงิน :</span>
                        <span className="bold">
                          {dataCoupon.couponPaymentDate == ""
                            ? "-"
                            : dataCoupon.couponPaymentDate}
                        </span>
                      </li>
                    </>
                  )}
                  <li
                    className="body-list-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "15px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="light"
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      วันที่ครบกำหนดรับเงิน
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltip}
                        onClick={handleTooltip}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="ขอแนะนำให้ท่านใช้ บัญชีธนาคารกรุงเทพ  /  กสิกรไทย /  ไทยพาณิชย์ เพื่อได้รับเงินโอนภายในวันที่ครบกำหนดจ่ายฯ ยกเว้นในกรณีที่กรมธรรม์มีหนี้สิน ท่านจะได้รับเงินภายใน 10 วัน "
                        placement="top-end"
                        sx={{ color: "rgba(255, 94, 67, 1.0)" }}
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      :
                    </span>
                    <span className="bold">{dataCoupon.couponDueDate}</span>
                  </li>

                  <li
                    className="body-list-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // marginRight: "10px",
                      fontSize: "15px",
                    }}
                  >
                    <span className="light">ช่องทางการรับเงิน :</span>
                    {dataCoupon.policyIServicePaymentFlag == "Y" &&
                    dataCoupon.couponPaymentType == "" ? (
                      <span
                        className="warning-word"
                        style={{ textAlign: "end" }}
                        // className="bold text-danger"
                      >
                        {dataCoupon.policyIServicePaymentMeg}
                      </span>
                    ) : dataCoupon.policyIServicePaymentFlag == "N" &&
                      dataCoupon.couponPaymentType == "" ? (
                      <span
                        className="warning-word"
                        style={{ textAlign: "end" }}
                        // className="bold text-danger"
                      >
                        ท่านยังไม่ได้เพิ่มบัญชีธนาคารเพื่อรับเงินคืน
                      </span>
                    ) : (
                      <span
                        className="warning-word"
                        style={{ textAlign: "end" }}
                      >
                        {dataCoupon.couponPaymentType}
                      </span>
                    )}
                  </li>
                  <li
                    className="body-list-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // marginRight: "10px",
                      fontSize: "15px",
                    }}
                  >
                    <span className="light">ธนาคาร :</span>
                    <span className="bold">
                      {dataCoupon.couponBankName
                        ? dataCoupon.couponBankName
                        : "-"}
                    </span>
                  </li>
                  <li
                    className="body-list-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // marginRight: "10px",
                      fontSize: "15px",
                    }}
                  >
                    <span className="light">เลขที่บัญชี / เลขที่เช็ค :</span>
                    <span className="bold">
                      {dataCoupon.couponBankAccount
                        ? maskAccountNo(dataCoupon.couponBankAccount)
                        : "-"}
                    </span>
                  </li>
                </ul>
                {dataCoupon.policyIdnvFlag == "N" &&
                  dataCoupon.policyIServiceIdnvFlag == "Y" && (
                    <div
                      className="light warning-word"
                      style={{ paddingTop: "10px" }}
                    >
                      **
                      {dataCoupon.policyIServiceIdnvMeg}
                    </div>
                  )}
                {dataCoupon.policyIdnvFlag == "N" &&
                  dataCoupon.policyIServiceIdnvFlag == "N" && (
                    <div className="light warning-word">
                      ** ท่านยังไม่แนบเอกสารแสดงตน
                    </div>
                  )} */}
                <br />
              </div>
              {/* <Row className="d-flex flex-column mt-5 mb-5">
                <Col className="d-flex justify-content-end px-5 gap-2">
                  <button
                    style={{ fontSize: "15px", marginTop: "-20px" }}
                    onClick={goToSendDoc}
                    className="btn-send-doc w-sm-25 w-lg-50 p-2 bold"
                  >
                    แนบเอกสารหรือเปลี่ยนแปลงบัญชีธนาคาร
                  </button>
                </Col>
                <Col className="d-flex justify-content-start px-5 mb-5">
                  <button
                    style={{ fontSize: "15px" }}
                    onClick={goBack}
                    className="btn-checkout-back w-100 p-2 bold"
                  >
                    ย้อนกลับ
                  </button>
                </Col>
              </Row> */}
              {/* <br /> */}
              <Grid container spacing={1} className="justify-content-center flex-wrap">
                <Grid item xs={12} sm={6} >
                  <button
                    style={{ fontSize: "15px" }}
                    onClick={goBack}
                    className="btn-coupon-back w-sm-25 w-lg-50 p-2 bold"
                  >
                    ย้อนกลับ
                  </button>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <button
                    style={{ fontSize: "15px" }}
                    onClick={goToSendDoc}
                    className="btn-send-doc w-sm-25 w-lg-50 p-2 bold"
                  >
                    แนบเอกสารหรือเปลี่ยนแปลงบัญชีธนาคาร
                  </button>
                </Grid>

              </Grid>
              {/* <Grid container spacing={1} className="justify-content-center flex-wrap">
                <Grid item xs={12} sm={6} >
                  <button
                    style={{ fontSize: "15px" }}
                    onClick={goBack}
                    className="btn-coupon-back w-sm-25 w-lg-50 p-2 bold"
                  >
                    ย้อนกลับ
                  </button>
                </Grid>
              </Grid> */}
            </Tab>
            <Tab eventKey="profile" title="ประวัติการรับเงิน">
              <div className="card-custom col-12">
                <ul
                  className="list-group list-group-flush"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5"
                    style={{ paddingBottom: "15px" }}
                  >
                    <CollapseTable data={dataCoupon} />
                  </div>
                  <div className="light warning-word">
                    กรณีรับเงินคืนก่อนวันที่ 5 พฤศจิกายน 2562
                    สามารถสอบถามข้อมูลได้ที่ฝ่ายลูกค้าสัมพันธ์ {cusTel}
                  </div>
                  <br />
                </ul>
              </div>
              {/* <Row className="d-flex flex-column mt-5 mb-5">
                <Col className="d-flex justify-content-end px-5 gap-2">
                  <button
                    style={{ fontSize: "15px", marginTop: "-20px" }}
                    onClick={goToSendDoc}
                    className="btn-checkout-premium w-100 p-2 bold"
                  >
                    แนบเอกสารหรือเปลี่ยนแปลงบัญชีธนาคาร
                  </button>
                </Col>
                <Col className="d-flex justify-content-start px-5">
                  <button
                    style={{ fontSize: "15px" }}
                    onClick={goBack}
                    className="btn-checkout-back w-100 p-2 bold"
                  >
                    ย้อนกลับ
                  </button>
                </Col>
              </Row> */}
              <Grid container spacing={1} className="justify-content-center flex-wrap">
                <Grid item xs={12} sm={6} >
                  <button
                    style={{ fontSize: "15px" }}
                    onClick={goBack}
                    className="btn-coupon-back w-sm-25 w-lg-50 p-2 bold"
                  >
                    ย้อนกลับ
                  </button>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <button
                    style={{ fontSize: "15px" }}
                    onClick={goToSendDoc}
                    className="btn-send-doc w-sm-25 w-lg-50 p-2 bold"
                  >
                    แนบเอกสารหรือเปลี่ยนแปลงบัญชีธนาคาร
                  </button>
                </Grid>

              </Grid>
            </Tab>
          </Tabs>
        )}
      </div>
    </Fragment>
  );
};

export default RefundPolicy;
