import React, { useState, useEffect } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import uploadImg from "../../images/upload.svg";
import "./refundPolicy-styles.css";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { uploadFileAPI } from "./RefundService/refundService";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../util/Notification";
import PreviewMore20 from "./PreviewMore20";
import PreviewMore7Less20 from "./PreviewMore7Less20";
import PreviewLess7 from "./PreviewLess7";
import { Grid } from "@mui/material";

const BrowseFile = () => {
  let navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [ageLessThan7Years, setAgeLessThan7Years] = useState(false);
  const [ageBetween7And20Years, setageBetween7And20Years] = useState(false);
  const [ageMoreThan20Years, setageMoreThan20Years] = useState(false);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dataParams = location.state || {};

  const policyNo = dataParams.policyNo || "";
  const age = dataParams.age || "";

  const [filesInfo, setFilesInfo] = useState({});

  const fileDescriptions1 = [
    "สำเนาสูติบัตรของผู้เยาว์ เซ็นสำเนาถูกต้อง โดยผู้ปกครองโดยชอบธรรม",
    "สำเนาบัตรประชาชนของผู้ปกครอง โดยชอบธรรม์ เซ็นสำเนาถูกต้อง",
    "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชนของผู้ปกครองโดยชอบธรรม",
    "รูปถ่ายหน้าตรงที่เป็นปัจจุบันของผู้เยาว์",
    "สำเนาทะเบียนบ้านของผู้เยาว์ เซ็นสำเนาถูกต้อง โดยผู้ปกครองโดยชอบธรรม",
  ];

  const fileDescriptions2 = [
    "สำเนาบัตรประชาชนของผู้เยาว์ เซ็นสำเนาถูกต้อง",
    "สำเนาบัตรประชาชนของ ผู้ปกครองโดยชอบธรรม์ เซ็นสำเนาถูกต้อง",
    "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชนของผู้เยาว์",
    "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชนของผู้ปกครอง โดยชอบธรรม",
    "สำเนาทะเบียนบ้านของผู้เยาว์",
  ];
  const fileDescriptions3 = [
    "สำเนาบัตรประชาชน เซ็นสำเนาถูกต้อง",
    "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชน",
  ];

  const [files1, setFiles1] = useState(
    Array(fileDescriptions1.length).fill(null)
  );
  const [files2, setFiles2] = useState(
    Array(fileDescriptions2.length).fill(null)
  );
  const [files3, setFiles3] = useState(
    Array(fileDescriptions3.length).fill(null)
  );
  const [toggleImg, setToggleImg] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [completedModal, setCompletedModal] = useState(false);

  const handleFileUpload = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    let flagAge = "";
    let amountFile = 0;
    let files = [];

    if (ageMoreThan20Years) {
      // console.log('ageMoreThan20Years');
      amountFile = 2;
      files = files3;
      flagAge = "ageMoreThan20Years";
    } else if (ageLessThan7Years) {
      // console.log('ageLessThan7Years');
      files = files1;
      amountFile = 5;
      flagAge = "ageLessThan7Years";
    } else if (ageBetween7And20Years) {
      // console.log('ageBetween7And20Years');
      amountFile = 5;
      files = files2;
      flagAge = "ageBetween7And20Years";
    }

    // console.log("file: " + files);
    // console.log("amountFile :" + amountFile);

    // Ensure that the correct flagFileAge array is being iterated over
    files.forEach((f, index) => {
      // console.log(f);
      // console.log(index);

      if (f != null) {
        formData.append("files", f, f.name);
        formData.append("codeFile", index);
        // console.log(f);
      }
    });

    // Check if all necessary files have been appended
    const totalFiles = formData.getAll("files").length;
    // console.log("Total files in formData:", totalFiles);

    // If no files were added, trigger the warning
    if (totalFiles === 0) {
      notifyWarning("โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ");
      setLoading(false);
      return;
    }

    // console.log(amountFile);
    // console.log(formData.getAll("files").length);
    if (formData.getAll("files").length === amountFile) {
      formData.append("type", "11"); // ยืนยันตัวตน
      formData.append("policyNo", policyNo);
      // formData.append("files", files);
      formData.append("flagAge", flagAge);

      // console.log("FormData contents:", formData);

      setLoading(true);
      const result = await uploadFileAPI(formData);

      if (result.status) {
        notifySuccess("บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ");
        setTimeout(() => {
          setLoading(false);
          goChangePayment();
        }, 1500);
      } else {
        notifyError("เกิดข้อผิดพลาดไม่สามารถทำรายการได้");
        // console.log("Upload result error:", result);
      }
    } else {
      notifyWarning(
        "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ"
      );
    }
  };

  useEffect(() => {
    if (age < 7) {
      setTitle("ผู้เยาว์ (อายุต่ำกว่า 7 ปี)");
      setAgeLessThan7Years(true);
    } else if (age >= 7 && age < 20) {
      setTitle("ผู้เยาว์ (อายุตั้งแต่ 7 ปีขึ้นไป แต่น้อยกว่า 20 ปี)");
      setageBetween7And20Years(true);
    } else {
      setTitle("ผู้เอาประกัน (อายุตั้งแต่ 20 ปีขึ้นไป)");
      setageMoreThan20Years(true);
    }
  }, [age]);

  const goback = () => {
    navigate(-1, {
      state: {
        policyNo: policyNo,
        age: age,
      },
    });
  };

  const goChangePayment = () => {
    navigate(`/channelPay`, {
      replace: true,
      state: {
        policyNo: policyNo,
        age: age,
      },
    });
  };

  const handleFilesInfo = (fileKey, e) => {
    let fileName = e.target.files[0].name;

    let dataFileInfo = {
      [fileKey]: fileName,
    };

    setFilesInfo({ ...filesInfo, ...dataFileInfo });
  };

  return (
    <>
      <Header_Home />
      <Header title="ส่งเอกสารแสดงตัวตน" />
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      )}
      <div className="containers vh-100 px-4 pt-5">
        <div className="frame-custom  h-auto">
          <div className="d-flex px-3 pt-3 pb-3">
            <h2 className="font-size-title">นำส่งเอกสารแสดงตัวตน {title}</h2>
          </div>
          <hr
            className="mx-2"
            style={{ margin: "0px 0px 15px 0px", border: "solid 1px" }}
          />
          {ageMoreThan20Years && (
            <>
              <Form onSubmit={handleFileUpload}>
                <PreviewMore20
                  setFiles3={setFiles3}
                  fileDescriptions3={fileDescriptions3}
                  files3={files3}
                />
                {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    สำเนาบัตรประชาชน เซ็นสำเนาถูกต้อง
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file1"
                    className="btn-upload-file font-size-content-upload d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file1"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file1", e)}
                  />
                </div> */}
                {/* {filesInfo.file1 && (
                  <p style={{ fontSize: 10 }} className="ml-3 text-danger">
                    {filesInfo.file1 || ""}
                  </p>
                )}
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชน
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file2"
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file2"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file2", e)}
                  />
                </div> */}
                {/* {filesInfo.file2 && (
                  <p style={{ fontSize: 10 }} className="ml-3 text-danger">
                    {filesInfo.file2 || ""}
                  </p>
                )} */}
                <Row className="d-flex flex-column mt-5 mb-5">
                  <Col className="d-flex justify-content-end px-5 gap-2">
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={6}>
                        <input
                          type="submit"
                          // style={{ fontSize: 15 }}
                          value="ถัดไป"
                          className="btn-checkout-premium w-100 p-2 bold"
                        ></input>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col className="d-flex justify-content-start px-5">
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={6}>
                        <button
                          onClick={goback}
                          type="button"
                          className="btn-checkout-back w-100 p-2 bold"
                        >
                          ย้อนกลับ
                        </button>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Form>
            </>
          )}

          {ageLessThan7Years && (
            <Form onSubmit={handleFileUpload}>
              <PreviewLess7
                setFiles1={setFiles1}
                fileDescriptions1={fileDescriptions1}
                files1={files1}
              />
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  สำเนาสูติบัตรของผู้เยาว์ เซ็นสำเนาถูกต้อง
                  โดยผู้ปกครองโดยชอบธรรม*
                </div>
                <label
                  htmlFor="file1"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file1"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file1", e)}
                />
                {filesInfo.file1 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file1 || ""}
                  </p>
                )}
              </div> */}
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  สำเนาบัตรประชาชนของผู้ปกครอง โดยชอบธรรม์ เซ็นสำเนาถูกต้อง*
                </div>
                <label
                  htmlFor="file2"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file2"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file2", e)}
                />
                {filesInfo.file2 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file2 || ""}
                  </p>
                )}
              </div> */}
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชนของผู้ปกครองโดยชอบธรรม์*
                </div>
                <label
                  htmlFor="file3"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file3"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file3", e)}
                />
                {filesInfo.file3 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file3 || ""}
                  </p>
                )}
              </div> */}
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  รูปถ่ายหน้าตรงที่เป็นปัจจุบันของผู้เยาว์*
                </div>
                <label
                  htmlFor="file4"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file4"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file4", e)}
                />
                {filesInfo.file4 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file4 || ""}
                  </p>
                )}
              </div> */}
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  สำเนาทะเบียนบ้านของผู้เยาว์ เซ็นสำเนาถูกต้อง
                  โดยผู้ปกครองโดยชอบธรรม *
                </div>
                <label
                  htmlFor="file5"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file5"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file5", e)}
                />
                {filesInfo.file5 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file5 || ""}
                  </p>
                )}
              </div> */}
              <Row className="d-flex flex-column mt-5 mb-5">
                <Col className="d-flex justify-content-end px-5 gap-2">
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6}>
                      <input
                        type="submit"
                        // style={{ fontSize: 15 }}
                        // onClick={goChangePayment}
                        value="ถัดไป"
                        className="btn-checkout-premium w-100 p-2 bold"
                      ></input>
                    </Grid>
                  </Grid>
                </Col>
                <Col className="d-flex justify-content-start px-5">
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6}>
                      <button
                        onClick={goback}
                        type="button"
                        className="btn-checkout-back w-100 p-2 bold"
                      >
                        ย้อนกลับ
                      </button>
                    </Grid>
                  </Grid>
                </Col>
              </Row>
            </Form>
          )}

          {ageBetween7And20Years && (
            <Form onSubmit={handleFileUpload}>
              <PreviewMore7Less20
                setFiles2={setFiles2}
                fileDescriptions2={fileDescriptions2}
                files2={files2}
              />
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  สำเนาบัตรประชาชนของผู้เยาว์ เซ็นสำเนาถูกต้อง*
                </div>
                <label
                  htmlFor="file1"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file1"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file1", e)}
                />
                {filesInfo.file1 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file1 || ""}
                  </p>
                )}
              </div> */}
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  สำเนาบัตรประชาชนของ ผู้ปกครองโดยชอบธรรม์ เซ็นสำเนาถูกต้อง*
                </div>
                <label
                  htmlFor="file2"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file2"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file2", e)}
                />
                {filesInfo.file2 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file2 || ""}
                  </p>
                )}
              </div> */}
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชนของผู้เยาว์*
                </div>
                <label
                  htmlFor="file3"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file3"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file3", e)}
                />
                {filesInfo.file3 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file3 || ""}
                  </p>
                )}
              </div> */}
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชนของผู้ปกครอง
                  โดยชอบธรรม์*
                </div>
                <label
                  htmlFor="file4"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file4"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file4", e)}
                />
                {filesInfo.file4 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file4 || ""}
                  </p>
                )}
              </div> */}
              {/* <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-3">
                <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                  สำเนาทะเบียนบ้านของผู้เยาว์*
                </div>
                <label
                  htmlFor="file5"
                  className="btn-upload-file d-flex flex-row justify-content-center align-items-center font-size-content-upload"
                >
                  <i className="fa fa-cloud-upload"></i> อัพโหลด
                  <img
                    className="icon-image-content-upload"
                    src={uploadImg}
                    style={{ textAlign: "center" }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file5"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilesInfo("file5", e)}
                />
                {filesInfo.file5 && (
                  <p className="ml-3 text-danger" style={{ fontSize: 10 }}>
                    {filesInfo.file5 || ""}
                  </p>
                )}
              </div> */}
              <Row className="d-flex flex-column mt-5 mb-5">
                <Col className="d-flex justify-content-end px-5 gap-2">
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6}>
                      <input
                        type="submit"
                        // style={{ fontSize: 15 }}
                        // onClick={goChangePayment}
                        value="ถัดไป"
                        className="btn-checkout-premium w-100 p-2 bold"
                      ></input>
                    </Grid>
                  </Grid>
                </Col>
                <Col className="d-flex justify-content-start px-5">
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6}>
                      <button
                        onClick={goback}
                        type="button"
                        className="btn-checkout-back w-100 p-2 bold"
                      >
                        ย้อนกลับ
                      </button>
                    </Grid>
                  </Grid>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

export default BrowseFile;
