import React from "react";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CircularProgress from "@mui/material/CircularProgress";

export const CustomInputAutocomplete = (props) => {
  const {
    id,
    value,
    onChange,
    label,
    placeholder,
    disabled,
    loading,
    options,
    getOptionLabel,
    isOptionEqualToValue,
    error,
    errorText,
    ...other
  } = props;

  return (
    <>
      <InputLabel htmlFor={id} className="label text-black">
        {label}
      </InputLabel>
      <Autocomplete
        className="mb-2"
        size="small"
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionKey={(option) => option.id}
        isOptionEqualToValue={isOptionEqualToValue}
        loading={loading}
        loadingText={"กำลังโหลดข้อมูล..."}
        noOptionsText={"ไม่พบข้อมูล"}
        clearIcon={<ClearRoundedIcon />}
        popupIcon={<ExpandMoreRoundedIcon />}
        sx={{
          "& .MuiAutocomplete-inputRoot.Mui-disabled": {
            backgroundColor: "#ECE9E9A6",
          },
        }}
        renderInput={({ InputProps, ...params }) => (
          <TextField
            InputProps={{
              ...InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {InputProps.endAdornment}
                </>
              ),
              sx: {
                ...InputProps.sx,
                borderRadius: 16,
                "& .MuiIconButton-root": { marginBottom: "0px !important;" },
              },
            }}
            {...params}
            variant="outlined"
            placeholder={placeholder}
            error={error}
            helperText={error ? errorText || "กรุณาเลือก" : undefined}
          />
        )}
        id={id}
        value={value || null}
        onChange={(e, value, reason, detail) => onChange(value)}
        disabled={disabled}
        {...other}
      />
    </>
  );
};
