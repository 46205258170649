import React, { useEffect, useState, useRef } from "react";
import "../../App.css";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import {
  getDownloadDoc,
  getFileByApi,
  getFileFromOneDrive,
  downloadLetterDigiCom,
  getDataDownloadByPolicyAPI,
} from "../DownloadDoc/DownloadDocService/downloadDocService";
import imgDoc from "../../images/Arhive_load.png";
import CryptoJS from "crypto-js";
import "../DownloadDoc/downloadDoc-styles.css";
import { Modal, Col, Button } from "react-bootstrap";
import { data } from "jquery";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Checkbox, Typography, Box, Divider } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import { GrDocumentDownload } from "react-icons/gr";
import moment from "moment";

const DownloadDoc = () => {
  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            // backgroundColor: "#FFA500",
          },
        },
      },
    },
  });
  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  let navigate = useNavigate();
  const [cusTel, setCusTel] = useState("");
  const username = localStorage.getItem("customer_id");
  const [dataDoc, setDataDoc] = useState([]);
  const [checkedPolicies, setCheckedPolicies] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const radiosRef = useRef([]);
  const [showModal, setShowModal] = useState(false);
  const [spinner, setSpinner] = useState();
  const [rowDigiCom, setRowDigiCom] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [LoadingContent, setLoadingContent] = useState(false);

  useEffect(() => {
    getTel();
    getDataDownloadDoc();
  }, []);

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    setCusTel(cusTel.variable1);
  };

  const getDataDownloadDoc = async () => {
    setSpinner(true);
    const result = await getDownloadDoc(
      decryptCardNumber(username, process.env.REACT_APP_ENCRYPT_DATA)
    );
    if (result.success) {
      setTimeout(() => {
        setDataDoc(result.data.result);
        setSpinner(false);
      }, 1000);

      // console.log(result.data.result);
    }
  };

  const handleSelectPolicy = (file, policyNo, idx) => {
    // ตรวจสอบว่า file.selectedPolicies ถูกกำหนดไว้หรือยัง ถ้ายังให้เริ่มต้นเป็น array ว่างๆ
    if (!file.selectedPolicies) {
      file.selectedPolicies = [];
    }

    // ตรวจสอบว่า policyNo นี้ถูกเลือกไปแล้วหรือยัง
    const isChecked = file.selectedPolicies.includes(policyNo);

    if (isChecked) {
      // ถ้า policyNo นี้ถูกติ๊กออก ให้ลบมันออกจาก selectedPolicies
      file.selectedPolicies = file.selectedPolicies.filter(
        (policy) => policy !== policyNo
      );
    } else {
      // ถ้า policyNo นี้ยังไม่ได้เลือก ให้เพิ่มมันเข้าไปใน selectedPolicies
      file.selectedPolicies.push(policyNo);
    }

    // อัปเดต checkedPolicies ที่เป็น state หลักที่เก็บข้อมูลทั่วทั้งแอปพลิเคชัน
    setCheckedPolicies((prevPolicies) => {
      // คัดเลือก policy ที่ได้เลือกไว้ และเพิ่มเข้าหรืออัปเดต
      const updatedPolicies = isChecked
        ? prevPolicies.filter(
            (policy) => !(policy.policy_no === policyNo && policy.file === file)
          )
        : [...prevPolicies, { policy_no: policyNo, file }];

      // ตรวจสอบว่าเลือก policy ทั้งหมดในไฟล์นี้แล้วหรือยัง
      const allPoliciesInFileChecked =
        dataDoc[idx]?.policies &&
        file.selectedPolicies.length === dataDoc[idx].policies.length;

      // อัปเดตสถานะ allChecked ให้ตรวจสอบว่าเลือกครบทั้งหมดหรือไม่
      const allChecked = dataDoc.every((doc) =>
        updatedPolicies.some((policy) => policy.policy_no === doc.policy_no)
      );

      setIsAllChecked(allChecked);

      // ถ้าทุก policy ในไฟล์นี้ถูกเลือกแล้ว ก็อัปเดต checkedPolicies ทั้งหมด
      if (allPoliciesInFileChecked) {
        setCheckedPolicies((prev) => [
          ...prev,
          ...dataDoc[idx].policies.map((policy) => ({
            policy_no: policy.policyNo,
            file,
          })),
        ]);
      }

      // Log to see the updated policy numbers only
      console.log(updatedPolicies.map((policy) => policy.policy_no));

      // ตรวจสอบว่าทุก policy ถูกติ๊กออกหมดแล้วหรือไม่
      if (updatedPolicies.length === 0) {
        setRowDigiCom([]); // เคลียร์ข้อมูล rowDigiCom
      } else {
        // Now you can call getDataDownloadByPolicy here with the updated policies
        updatedPolicies.forEach(async (policy) => {
          await getDataDownloadByPolicy(
            updatedPolicies.map((policy) => policy.policy_no)
          );
        });
      }

      return updatedPolicies;
    });
  };

  const handleSelectAll = () => {
    const newCheckedState = !isAllChecked;
    setIsAllChecked(newCheckedState);

    if (newCheckedState) {
      // ถ้าเลือกทั้งหมด
      const allPolicies = dataDoc.map((item) => ({
        policy_no: item.policy_no,
        file: item.file,
      }));
      setCheckedPolicies(allPolicies);
      console.log(allPolicies.map((policy) => policy.policy_no));

      // เรียก getDataDownloadByPolicy แค่ครั้งเดียว
      const allPolicyNos = allPolicies.map((policy) => policy.policy_no);
      getDataDownloadByPolicy(allPolicyNos);

      // อัปเดต selectedPolicies สำหรับแต่ละ item
      dataDoc.forEach((item) => {
        if (!item.file.selectedPolicies) {
          item.file.selectedPolicies = [];
        }
        item.file.selectedPolicies = [item.policy_no];
      });
    } else {
      // ถ้าติ๊กออกทั้งหมด
      setCheckedPolicies([]);
      setRowDigiCom([]); // เคลียร์ข้อมูล rowDigiCom

      // ล้าง selectedPolicies สำหรับแต่ละ item
      dataDoc.forEach((item) => {
        item.file.selectedPolicies = [];
      });
    }
  };

  const convertBase64ToPDF = (base64String, fileName) => { 
    const finalFileName = fileName.toLowerCase().endsWith(".pdf") 
      ? fileName 
      : fileName + ".pdf";
  
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    saveAs(blob, finalFileName);
  };
  

  const getFileFromOneDriveAPI = async (fileName) => {
    // if (!fileName.toLowerCase().endsWith(".pdf")) {
    //   fileName += ".pdf";
    // }

    // console.log(fileName);
    const result = await getFileFromOneDrive(fileName);
    if (result.status) {
      // console.log(result.downloadUrl);
      convertBase64ToPDF(result.base64, fileName.trim());
    } else {
      setShowModal(true);
    }
  };

  const downloadDocument = async (policyNo, fileName, flag) => {
    console.log(policyNo);
    // console.log(receiptNo);
    console.log(fileName);
    console.log(flag);
    console.log(dataDoc);
    try {
      // if (flag == "api") {
      //   await getFileByApi(policyNo, receiptNo, fileName);
      // } else
      if (flag == "digital_com") {
        await downloadLetterDigiComAPI(fileName);
      } else {
        console.log(`Downloading from OneDrive: ${fileName}`);
        await getFileFromOneDriveAPI(fileName);
      }
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  const findFileByPolicyNo = (policyNo, fileName) => {
    const foundPolicy = dataDoc.find((doc) => doc.policy_no === policyNo);
    if (foundPolicy) {
      const foundFile = foundPolicy.file.find(
        (file) => file.file_name === fileName
      );
      return foundFile ? foundFile : null;
    }
    return null;
  };

  const handleDownload = (policyNo, fileName, flag) => {
    setSpinner(true);

    const fileData = findFileByPolicyNo(policyNo, fileName);
    if (fileData) {
      // console.log("พบไฟล์:", fileData.file_name, fileData.receipt_no);
      downloadDocument(policyNo, fileData.receipt_no, fileData.file_name, flag);
      setTimeout(() => {
        setSpinner(false);
      }, 1000);
    }
  };

  const downloadLetterDigiComAPI = async (fileName) => {
    // if (!fileName.toLowerCase().endsWith(".pdf")) {
    //   fileName += ".pdf";
    // }
    // console.log(fileName);
    const result = await downloadLetterDigiCom(fileName);
    if (result.status) {
      console.log(result.status);
      // console.log(result.downloadUrl);
      convertBase64ToPDF(result.base64, fileName.trim());
    } else {
      setShowModal(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDataDownloadByPolicy = async (policyNo) => {
    setLoadingContent(true);
    const result = await getDataDownloadByPolicyAPI(policyNo);
    setRowDigiCom(result);
    setLoadingContent(false);
  };

  const removeDataDownloadByPolicy = (policyNo) => {
    downloadedData = rowDigiCom.filter((item) => item.policy_no !== policyNo);
  };

  return (
    <div>
      <Header_Home />
      <Header title="ดาวน์โหลดเอกสาร" />
      {spinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </div>
      )}
      <div className="table-page-container">
        <div style={{ marginLeft: "20px" }}>
          <span>
            ท่านสามารถดาวน์โหลดหนังสือรับรองชำระเบี้ยประกัน ใบเสร็จรับเงิน
            และจดหมายต่างๆ โดยเลือกกรมธรรม์ที่ท่านต้องการ
          </span>
        </div>
        <Box className="p-4 frame-other">
          {/* Header Checkbox for Select All */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 2,
              borderBottom: "0.5px solid #ccc",
              paddingBottom: 1,
            }}
          >
            <Checkbox
              id="insurance_desire"
              name="insurance_desire"
              checked={isAllChecked}
              onChange={handleSelectAll}
            />
            <Typography
              htmlFor="insurance_desire"
              component="label"
              sx={{ fontWeight: "bold" }}
            >
              ทุกกรมธรรม์
            </Typography>
          </Box>

          {/* Scrollable List of Policies */}
          <Box
            sx={{
              maxHeight: "400px", // กำหนดความสูงของ Scroll Window
              overflowY: "auto", // เปิดการเลื่อนในแนวตั้ง
              // border: "1px solid #ccc", // เพิ่มกรอบเพื่อความชัดเจน
              padding: 2,
            }}
          >
            {dataDoc
              .sort((a, b) => a.policy_no.localeCompare(b.policy_no)) // Sort by policy_no
              .map((item, idx) => (
                <Box key={idx} sx={{ marginBottom: 2 }}>
                  <Grid container alignItems="center" spacing={2}>
                    {/* Checkbox */}
                    <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                      <Checkbox
                        inputRef={(el) => (radiosRef.current[idx] = el)}
                        id={`insurance_desire_${idx}`}
                        name="insurance_desire"
                        checked={checkedPolicies.some(
                          (policy) => policy.policy_no === item.policy_no
                        )}
                        onChange={() =>
                          handleSelectPolicy(item.file, item.policy_no, idx)
                        }
                      />
                    </Grid>

                    {/* Label and Policy Number */}
                    <Grid item xs={10} sm={4} md={3} lg={2} xl={2}>
                      <Typography
                        component="label"
                        htmlFor={`insurance_desire_${idx}`}
                        sx={{ display: "block", fontWeight: "bold" }}
                      >
                        กรมธรรม์เลขที่:
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "flex-start", sm: "center" },
                      }}
                    >
                      <Typography
                        className="value-item"
                        sx={{ marginLeft: { xs: 7, sm: 2 }, color: "#555" }}
                      >
                        {item.policy_no}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 1 }} />
                </Box>
              ))}
          </Box>
        </Box>

        <div className="mt-3" style={{ marginLeft: "20px" }}>
          <span className="text-description">
            ท่านสามารถดาวน์โหลดหนังสือรับรองการชำระเบี้ยประกันย้อนหลังได้ 1 ปี
            หากต้องการเอกสารเพิ่มเติมสามารถติดต่อฝ่ายลูกค้าสัมพันธ์ {cusTel}
          </span>
        </div>
        <div style={{ padding: "10px", marginTop: "20px", marginLeft: "10px" }}>
          <h3 className="flex-shrink-1 bold">
            เอกสาร{" "}
            <img className="image align-self-center" src={imgDoc} alt="" />
          </h3>
        </div>
        <div style={{ position: "relative" }}>
          {LoadingContent ? (
            <div className="p-4">
              <div className="p-2">
                <Skeleton className="h-70px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
              </div>
            </div>
          ) : (
            <ThemeProvider theme={theme}>
              <TableContainer style={{ height: "auto" }} component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#e7e7e786" }}>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 50 }}
                      >
                        ดาวน์โหลด
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 50 }}
                      >
                        ลำดับ
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200 }}
                      >
                        กรมธรรม์เลขที่
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 350 }}
                      >
                        แบบประกัน
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 600 }}
                      >
                        หัวจดหมาย
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200 }}
                      >
                        วันที่ออกจดหมาย
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!(rowDigiCom.length === 0) ? (
                      rowDigiCom
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, idx) => {
                          const seq = idx + 1 + page * rowsPerPage;
                          return (
                            <TableRow key={idx}>
                              <TableCell
                                className="font-new"
                                align="center"
                                style={{ width: 50 }}
                              >
                                <GrDocumentDownload
                                  style={{
                                    cursor: "pointer",
                                    color: "black",
                                    fontSize: "24px",
                                  }}
                                  onClick={() =>
                                    downloadDocument(
                                      item.policy_no,
                                      item.letterFileName,
                                      item.flag
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className="font-new"
                                align="center"
                                style={{ width: 50 }}
                              >
                                {seq}
                              </TableCell>
                              <TableCell
                                className="font-new"
                                align="center"
                                style={{ width: 200 }}
                              >
                                {item.policyNo}
                              </TableCell>
                              <TableCell
                                className="font-new"
                                align="center"
                                style={{ width: 200 }}
                              >
                                {item.planName}
                              </TableCell>
                              <TableCell
                                className="font-new"
                                align="center"
                                style={{ width: 200 }}
                              >
                                {item.letterName}
                              </TableCell>
                              <TableCell
                                className="font-new"
                                align="center"
                                style={{ width: 200 }}
                              >
                                {item.date
                                  ? moment(item.date, "YYYY-MM-DD").format(
                                      "DD/MM/YYYY"
                                    )
                                  : ""}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableCell align="center" colSpan={8}>
                        <span className="font-new">ไม่พบข้อมูล</span>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rowDigiCom.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </ThemeProvider>
          )}
        </div>

        {/* <div
          className="p-4 frame-doc"
          style={{
            height: "auto",
            maxHeight: "200px", // กำหนดความสูงสูงสุดของ Scroll Window
            overflowY: "auto", // เปิดการเลื่อนในแนวตั้ง
            border: "1px solid #ccc", // เพิ่มกรอบเพื่อความชัดเจน
            padding: "10px",
          }}
        >
          {checkedPolicies.length > 0
            ? checkedPolicies.map((item1) =>
                item1.file.length > 0
                  ? item1.file.map((item2, idx2) => (
                      <div key={idx2}>
                        <ul
                          className="list-group"
                          style={{ marginBottom: "10px" }}
                        >
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginRight: "10px",
                              fontSize: 15,
                            }}
                          >
                            <span
                              onClick={() =>
                                handleDownload(
                                  item1.policy_no,
                                  item2.file_name,
                                  item2.flag
                                )
                              }
                              className="download-reciept pointer"
                              style={{
                                // width: 200,
                                // minWidth: 50,
                                cursor: "pointer",
                                color: "#007bff",
                                textDecoration: "underline",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.color = "#0056b3")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.color = "#007bff")
                              }
                            >
                              {item2.file_name}
                            </span>
                          </li>
                        </ul>
                      </div>
                    ))
                  : null
              )
            : "กรุณาเลือกกรมธรรม์"}
        </div> */}
        <br />

        <br />
        <br />
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header></Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            fontSize: "25px",
          }}
        >
          {/* <img
              src={imgTrash}
              style={{
                width: "50px",
                height: "50px",
              }}
            /> */}
          <p style={{ marginTop: "20px" }}>ไม่พบไฟล์ที่ต้องการ</p>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <a
            onClick={() => setShowModal(false)}
            type="button"
            className="mt-3 btn-back"
            style={{ minWidth: "100px", textAlign: "center" }}
          >
            ปิด
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DownloadDoc;
