import React, { Fragment, useState, useEffect } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import "../PayInsure/payInsure-styles.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {
  Grid
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CurrencyFormater, CurrencyFormaterNonDec } from "../../util";
import { downloadReceiptFileAPI } from "./payInsureService/payInsureService";
import { triggerBase64Download } from "react-base64-downloader";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import { getPolicyReceiptYear } from "../PayInsure/payInsureService/payInsureService";
import CryptoJS from "crypto-js";
import { saveAs } from "file-saver";

const PayInsure = () => {
  const [activeTab, setActiveTab] = useState();
  const location = useLocation();
  const rcData = location.state || {};
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [LoadingContent, setLoadingContent] = useState(true);
  const [cusTel, setCusTel] = useState("");
  // let [searchParams, setSearchParams] = useSearchParams();
  const dataParams = location.state || {};
  const policyNo = dataParams.policyNo || "";
  const insureIdCard = localStorage.getItem("customer_id") || "";
  const [dataReceipt, setDataReceipt] = useState(rcData.receipts);
  const [selectedYear, setSelectedYear] = useState("");
  const [receiptYears, setReceiptYears] = useState([]);
  const [filteredReceipts, setFilteredReceipts] = useState(
    rcData.receipts || []
  );
  const [riders, setRiders] = useState(dataParams.riders.length);

  useEffect(() => {
    setTimeout(() => {
      setLoadingContent(false);
    }, 1500);
    // console.log(dataReceipt);
    getTel();
    getPolicyReceiptYearAPI();
    // console.log(riders.length);
  }, [rcData]);

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const goBack = () => {
    navigate("/policylist");
  };

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const handleDownloadReceipt = async (policyNo, receiptNo) => {
    // console.log("download", policyNo);
    // console.log("download", receiptNo);

    setLoading(true);

    const resultDownload = await downloadReceiptFileAPI(policyNo, receiptNo);
    if (resultDownload.status) {
      // console.log(resultDownload);
      // triggerBase64Download(resultDownload.data, "download_img");
      // triggerBase64Download(
      //   `data:application/pdf;base64,${resultDownload.data}`
      // );
      convertBase64ToPDF(resultDownload.data, receiptNo);
      setLoading(false);
    }
  };

  const convertBase64ToPDF = (base64String, receiptNo) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    saveAs(blob, receiptNo + ".pdf");
  };

  const goToPayment = () => {
    navigate(`/payment`, { state: rcData });
    // console.log(activeIndex);
    // console.log(dataMaster[activeIndex]);
  };

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    const variable1 = cusTel.variable1;
    setCusTel(variable1);
  };

  const getPolicyReceiptYearAPI = async () => {
    const result = await getPolicyReceiptYear(
      policyNo,
      decryptCardNumber(insureIdCard, process.env.REACT_APP_ENCRYPT_DATA)
    );
    // console.log(result);
    if (result.status) {
      setReceiptYears(result.receiptYears);
    }
  };

  const handleYearChange = (e) => {
    const selected = e.target.value;
    setSelectedYear(selected);

    if (selected === "") {
      setFilteredReceipts(rcData.receipts);
    } else {
      const filteredReceipts = rcData.receipts.filter((item) => {
        const receiptYear = parseInt(item.receiptDate.split("/")[2], 10);
        // console.log(`Selected Year: ${selected}, Receipt Year: ${receiptYear}`);
        return receiptYear === parseInt(selected, 10);
      });
      // console.log("Filtered Receipts:", filteredReceipts);
      setFilteredReceipts(filteredReceipts);
    }
  };

  const policyDueDate = rcData.policyDueDate;

  const validateDisablePolicyDueDate = (policyDueDate) => {
    if (!policyDueDate) {
      return false;
    }
    const [day, month, buddhistYear] = policyDueDate.split("/");
    const christianYear = parseInt(buddhistYear) - 543;
    const dueDate = new Date(`${christianYear}-${month}-${day}`);
    dueDate.setHours(0, 0, 0, 0);

    const nextMonth = new Date();
    // const nextMonth = new Date("2024-10-30");
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    nextMonth.setHours(0, 0, 0, 0);

    // console.log("Due Date:", dueDate.toLocaleDateString());
    // console.log("Next Month:", nextMonth.toLocaleDateString());
    // console.log(nextMonth.getTime() === dueDate.getTime());

    return nextMonth.getTime() >= dueDate.getTime();
  };

  return (
    <>
      <Header_Home />
      <Header title="ชำระเบี้ยประกันภัย" />
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      )}
      <div className="containers p-4">
        {LoadingContent && (
          <>
            <Row className="card-custom3 d-flex">
              <Col className="mt-3">
                <Skeleton style={{ height: "30px" }} />
                <div className="d-flex flex-row justify-content-between">
                  <Skeleton />
                </div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Col>
            </Row>
            <br />
          </>
        )}
        {!LoadingContent && (
          <Tabs
            defaultActiveKey="home"
            id="fill-tab-example"
            fill
            className="mt-2"
            activeKey={activeTab}
            onSelect={handleSelect}
          >
            <Tab eventKey="home" title="เบี้ยประกันที่ต้องชำระ">
              <div className="card-custom col-12">
                <ul className="list-group list-group-flush p-2 body-list-group">
                  <li className="">
                    <h5 className="bold">
                      ปีที่ {rcData.policyYear} / งวดที่ {rcData.policyPeriod}
                    </h5>
                    <hr
                      style={{
                        margin: "10px 10px 15px 5px",
                        border: "solid 1px",
                        borderTop: 0.1,
                      }}
                    />
                    <Grid container spacing={1}>
                      <Grid item xs={7} sm={4}>
                        <span className="font-size-label">วันครบกำหนดชำระเบี้ย : </span>
                      </Grid>
                      <Grid item xs={5} sm={2} style={{ textAlign: "right" }}>
                        <span className="bold">{rcData.policyDueDate}</span>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <span className="font-size-label">เบี้ยประกันภัยหลัก : </span>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                        <span className="bold"> {CurrencyFormaterNonDec(rcData.policyBasePremiumAmount)}{" "} บาท</span>
                      </Grid>
                    </Grid>
                  </li>
                  {/* <li
                    className="mt-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: 15,
                    }}
                  >
                    <span className="">เบี้ยประกันภัยหลัก :</span>
                    <span className="bold">
                      {CurrencyFormaterNonDec(rcData.policyBasePremiumAmount)}{" "}
                      บาท
                    </span>
                  </li> */}
                  {riders > 0 && (
                    <li
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: 15,
                      }}
                    >
                      {/* <span className="">เบี้ยประกันภัย สัญญาเพิ่มเติม :</span>
                      <span className="bold">
                        {CurrencyFormaterNonDec(
                          rcData.policyRiderPremiumAmount
                        )}{" "}
                        บาท
                      </span> */}
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={3}>
                          <span className="font-size-label">เบี้ยประกันภัย สัญญาเพิ่มเติม :</span>
                        </Grid>
                        <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                          <span className="bold">{CurrencyFormaterNonDec(
                            rcData.policyRiderPremiumAmount
                          )}{" "}
                            บาท</span>
                        </Grid>
                      </Grid>
                    </li>
                  )}

                  <hr
                    style={{
                      margin: "10px 10px 15px 5px",
                      border: "solid 1px",
                      borderTop: 0.1,
                    }}
                  />
                  <li
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: 15,
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={3}>
                        <span className="font-size-label">รวมเบี้ยประกันภัยที่ต้องชำระ :</span>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                        <span className="bold">{CurrencyFormaterNonDec(rcData.policyTotalPremiumAmount)}{" "}
                          บาท</span>
                      </Grid>
                    </Grid>
                    {/* <span className="">รวมเบี้ยประกันภัยที่ต้องชำระ :</span>
                    <span className="bold">
                      {CurrencyFormaterNonDec(rcData.policyTotalPremiumAmount)}{" "}
                      บาท
                    </span> */}
                  </li>
                  <div className="mt-1" style={{ color: "red", fontSize: 10 }}>
                    หากเกินระยะเวลาที่กำหนด จำนวนเบี้ยประกันภัยที่ต้องชำระ
                    อาจมีการเปลี่ยนแปลง กรุณาติดต่อตัวแทนประกันชีวิตที่ดูแลท่าน
                    หรือติดต่อฝ่ายลูกค้าสัมพันธ์ {cusTel}
                  </div>
                </ul>
              </div>
              {rcData.policyPaymentAllowFlag == "Y" &&
                rcData.paymentFlagIservice == "Y" && (
                  <p
                    style={{ fontSize: "10px" }}
                    className="p-2 px-3 text-danger"
                  >
                    บริษัทฯ ได้รับเงินค่าเบี้ยประกันจากท่านเรียบร้อย
                    {/* บริษัทฯ ได้รับเงินค่าเบี้ยประกันจากท่านแล้ววันที่{" "}
                    {moment(rcData.paymentActualDate).format("DD/MM/yyyy") ??
                      ""}{" "}
                    และ จะนำไปชำระเบี้ยประกันให้กับท่านเมื่อถึงวันครบกำหนดชำระ{" "}
                    {rcData.policyDueDate ?? ""} */}
                  </p>
                )}
              {/* <Row className="d-flex  flex-column mt-2 mb-5">
                {rcData.policyPaymentAllowFlag == "Y" &&
                  rcData.paymentFlagIservice == "N" && (
                    <Col className="d-flex justify-content-end px-5 gap-2">
                      <button
                        style={{ fontSize: 18 }}
                        onClick={goToPayment}
                        className="btn-checkout-premium w-100 p-2 bold"
                      >
                        ชำระเบี้ยประกัน
                      </button>
                    </Col>
                  )}
                <Col className="d-flex justify-content-start px-5">
                  <button
                    onClick={goBack}
                    className="btn-coupon-back w-sm-100 w-lg-25 p-2 bold text-black"
                  >
                    ย้อนกลับ
                  </button>
                </Col>
              </Row> */}
            </Tab>
            <Tab eventKey="profile" title="ประวัติการชำระเบี้ยฯ">
              <div className="card-custom col-12">
                <ul className="list-group list-group-flush p-2 body-list-group pb-5">
                  <li>
                    <h5 className="bold">ปีที่</h5>
                    <select
                      className="form-control custom-fieldDDL"
                      name="receiptYear"
                      id="receiptYear"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      <option value="">-- เลือกปี --</option>{" "}
                      {receiptYears.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </li>
                  {filteredReceipts.length > 0 ? (
                    filteredReceipts.map((item, idx) => (
                      <Fragment key={idx}>
                        {/* <li
                          className="mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 15,
                          }}
                        >
                          <span>ใบเสร็จเลขที่ : {item.receiptNo}</span>
                          <span
                            onClick={() =>
                              handleDownloadReceipt(
                                rcData.policyNo,
                                item.receiptNo
                              )
                            }
                            className="bold download-reciept pointer"
                          >
                            ดาวน์โหลด
                          </span>
                        </li>
                        <li
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 15,
                          }}
                        >
                          <span>ค่าเบี้ยประกัน :</span>
                          <span className="bold">
                            {CurrencyFormater(item.receiptAmount)} บาท
                          </span>
                        </li>
                        <li
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 15,
                          }}
                        >
                          <span>ช่องทางการชำระ :</span>
                          <span className="bold">{item.paymentMethod}</span>
                        </li>
                        <li
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 15,
                          }}
                        >
                          <span>วันที่ลูกค้าชำระเบี้ย :</span>
                          <span className="bold">{item.receiptDate}</span>
                        </li>
                        <li
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 15,
                          }}
                        >
                          <span>วันที่ครบกำหนดชำระเบี้ย :</span>
                          <span className="bold">{item.premiumDueDate}</span>
                        </li>
                        <hr
                          style={{
                            margin: "10px 10px 15px 5px",
                            border: "solid 1px",
                            borderTop: 0.1,
                          }}
                        /> */}
                        <Grid container spacing={1}>
                          <Grid item xs={8} sm={6}>
                            <span>ใบเสร็จเลขที่ : {item.receiptNo}</span>
                          </Grid>
                          <Grid item xs={4} sm={6} style={{ textAlign: "right" }}>
                            <span
                              onClick={() =>
                                handleDownloadReceipt(
                                  rcData.policyNo,
                                  item.receiptNo
                                )
                              }
                              className="bold download-reciept pointer"
                            >
                              ดาวน์โหลด
                            </span>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <span>ค่าเบี้ยประกัน :</span>
                          </Grid>
                          <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                            <span className="bold">
                              {CurrencyFormater(item.receiptAmount)} บาท
                            </span>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <span>ช่องทางการชำระ :</span>
                          </Grid>
                          <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                            <span className="bold">{item.paymentMethod}</span>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <span>วันที่ลูกค้าชำระเบี้ย :</span>
                          </Grid>
                          <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                            <span className="bold">{item.receiptDate}</span>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <span>วันที่ครบกำหนดชำระเบี้ย :</span>
                          </Grid>
                          <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
                            <span className="bold">{item.premiumDueDate}</span>
                          </Grid>

                        </Grid>
                        <hr
                          style={{
                            margin: "10px 10px 15px 5px",
                            border: "solid 1px",
                            borderTop: 0.1,
                          }}
                        />
                      </Fragment>
                    ))
                  ) : (
                    <li
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: 15,
                        marginTop: 50,
                      }}
                    >
                      <span className="bold">ไม่มีข้อมูล</span>
                    </li>
                  )}
                </ul>
              </div>
            </Tab>
          </Tabs>
        )}
        {!LoadingContent && rcData.policyStatus == "ขาดผลบังคับ" && (
          <p style={{ fontSize: "10px", color: "red" }} className="p-2 px-3">
            เนื่องจากบริษัทฯ ยังไม่ได้รับชำระค่าเบี้ยประกัน
            จึงทำให้กรมธรรม์ของท่านขาดผลบังคับ
            เพื่อรักษาสิทธิประโยชน์ความคุ้มครอง กรุณาติดต่อ {cusTel}{" "}
            ขออภัยหากท่านชำระแล้ว ขอบคุณค่ะ
          </p>
        )}
        {/* <Row className="d-flex flex-column mt-2 mb-5">
          {rcData.policyPaymentAllowFlag == "Y" &&
            rcData.paymentFlagIservice == "N" && (
              <Col className="d-flex justify-content-end px-5 gap-2">
                <button
                  style={{ fontSize: 18 }}
                  onClick={goToPayment}
                  className="btn-checkout-premium w-100 p-2 bold"
                  disabled={!validateDisablePolicyDueDate(policyDueDate)}
                >
                  ชำระเบี้ยประกัน
                </button>
              </Col>
            )}
          <Col className="d-flex justify-content-start px-5">
            <button
              onClick={goBack}
              className="btn-coupon-back w-sm-100 w-lg-25 p-2 bold text-black"
            >
              ย้อนกลับ
            </button>
          </Col>
        </Row> */}
        <Grid container spacing={1} className="justify-content-center flex-wrap">
          <Grid item xs={12} sm={6} >
            <button
              onClick={goBack}
              className="btn-coupon-back w-sm-100 w-lg-25 p-2 bold text-black"
            >
              ย้อนกลับ
            </button>
          </Grid>
          {rcData.policyPaymentAllowFlag == "Y" &&
            rcData.paymentFlagIservice == "N" && (
              <Grid item xs={12} sm={6} >
                <button
                  style={{ fontSize: 18 }}
                  onClick={goToPayment}
                  className="btn-send-doc w-100 p-2 bold"
                  disabled={!validateDisablePolicyDueDate(policyDueDate)}
                >
                  ชำระเบี้ยประกัน
                </button>
              </Grid>
            )}


        </Grid>
      </div>
    </>
  );
};
export default PayInsure;
