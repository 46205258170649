import React, { useRef, useEffect, useState } from 'react';
import { Card, CardContent, Box, FormControlLabel, Checkbox, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { CardHeader } from '@mui/material';



const ScrollableCreditCard = ({ listData, cardData, setListData, fullName,
  cardNumber,
  month,
  year,
  type,
  bank,
  relation
}) => {
  const cardRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
  const [inputErrors, setInputErrors] = useState([]);
  // const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก

  useEffect(() => {
    // console.log(listData)
    const selectedIndexList = listData.map(item => item.index); // ดึง index จาก listData
    setSelectedIndexes(selectedIndexList);
    // console.log("cardRef.current:", cardRef.current);


  }, [cardData]); // เรียกครั้งเดียวหลัง 

  const formatDate = (dateString) => {
    const date = new Date(dateString); // แปลงสตริงเป็นวันที่

    // ตรวจสอบว่ามีวันที่ที่ถูกต้องหรือไม่
    if (isNaN(date)) {
      return "-"; // ถ้าวันที่ไม่ถูกต้อง ส่งกลับ "-"
    }

    const month = String(date.getMonth() + 1).padStart(2, "0"); // เดือนเริ่มจาก 0
    const year = String(date.getFullYear()).slice(-2); // ปีสองหลัก

    return `${month}/${year}`; // ฟอร์แมตเป็น mm/yy
  };

  const partialHide = (cardNumber) => {
    const hiddenCardNumber = cardNumber.split("");
    hiddenCardNumber.splice(4, 8, "********");
    return hiddenCardNumber.join("");
  };


  useEffect(() => {
    // console.log(listData)
    const updatedList = listData.map((item) => ({
      ...item,
      policy_no: item.policy_no.trim(),
      card_holder_name: fullName,
      card_number: cardNumber,
      card_expire_date: month + "/" + year,
      card_type: type,
      credit_bank_name: bank,
      card_relation: relation,
      premium_amount: item.premium_amount,
      next_premium_due: item.next_premium_due,
      old_card_holder_name: item.old_card_holder_name || "",
      old_card_number: item.old_card_number || "",
      old_card_expire_date: item.old_card_expire_date || "",
      old_card_type: item.old_card_type || "",
      old_credit_bank_name: item.old_credit_bank_name || "",
      old_card_relation: item.card_relation || "",
    }));



    setListData(updatedList);
  }, [fullName,
    cardNumber,
    month,
    year,
    type,
    bank,
    relation]);


  const CustomCheckbox = styled(Checkbox)({
    color: 'rgba(255, 94, 67, 0.5)',
    '&.Mui-checked': {
      color: '#FF5E43',
    },
    marginRight: '15px'
  });



  const handleBoxClick = (
    index,
    policy_no,
    old_card_holder_name,
    old_card_number,
    old_card_expire_date,
    old_card_type,
    old_credit_bank_name,
    premium_amount,
    next_premium_due,
    card_relation
  ) => {
    setListData([])
    setSelectedIndexes(() => {
      if (selectedIndexes.includes(index)) {
        // ถ้าถูกเลือกแล้ว ให้ลบออกจาก array (ทำให้ไม่มีการเลือก)
        removeItemByPolicyNo(policy_no);
        return []; // ล้าง selectedIndexes
      } else {
        // ถ้ายังไม่ถูกเลือก ให้เพิ่มเข้าไปใน array (และล้างการเลือกก่อนหน้านี้)
        const updatedList = {
          index:index,
          policy_no: policy_no.trim(),
          card_holder_name: fullName,
          card_number: cardNumber,
          card_expire_date: month + "/" + year,
          card_type: type,
          credit_bank_name: bank,
          card_relation: relation,
          premium_amount: premium_amount,
          next_premium_due: next_premium_due,
          old_card_holder_name: old_card_holder_name || "",
          old_card_number: old_card_number || "",
          old_card_expire_date: old_card_expire_date || "",
          old_card_type: old_card_type || "",
          old_credit_bank_name: old_credit_bank_name || "",
          old_card_relation: card_relation || "",
        };

        addItem(updatedList);
        return [index]; // เก็บ index ของรายการที่เลือกใหม่ (ล้าง index ก่อนหน้านี้)
      }
    });
  };

  const addItem = (newItem) => {
    setListData((prevListData) => [...prevListData, newItem]);
  };

  const removeItemByPolicyNo = (policyNo) => {
    setListData((prevListData) => prevListData.filter(item => item.policy_no.trim() !== policyNo.trim()));
  };



  const getCardTypeNameByCode = (code) => {
    const findCardType = cardTypes.find((item) => item.value === code);
    return findCardType ? findCardType.label : code;
  };


  const cardTypes = [
    { value: "AM", label: "Amex" },
    { value: "C4", label: "JCB" },
    { value: "MC", label: "Mastercard" },
    { value: "VI", label: "Visa" },
  ];

  return (
    <Card ref={cardRef} sx={{ maxHeight: '490px', overflowY: 'auto' }}>
      <CardContent
        sx={{
          maxHeight: '490px',
          overflowY: 'auto',
          border: '1px solid rgba(255, 94, 67, 0.3)', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: '12px', // ความโค้งของขอบ
          borderWidth: '2px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* <Box>
            <FormControlLabel
              control={<CustomCheckbox onClick={handleCheckAll} checked={isChecked} />}`
              label={<Typography style={{ fontSize: '20px' }}>ทุกกรมธรรม์</Typography>}`
            />
          </Box> */}

        <Typography
          variant="body2"
          sx={{ color: 'red', marginTop: '5px', marginLeft: '5px' }} // ข้อความสีแดงพร้อม margin ด้านบน
        >
          *กรุณาเลือกกรมธรรม์
        </Typography>

        {cardData.map((_, index) => (
          <Box
            key={index}
            onClick={() =>
              handleBoxClick(
                index,
                _.policy_no,
                _.card_holder_name,
                _.card_number,
                _.card_expire_date,
                _.card_type,
                _.credit_bank_name,
                _.premium_amount,
                _.next_premium_due,
                _.card_relation
              )
            }
            sx={{
              marginBottom: "15px",
              padding: "10px",
              transition: "background-color 0.3s ease, border 0.3s ease",
              cursor: "pointer",
              alignItems: "center", // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
              justifyContent: "space-between", // แยกข้อความทางซ้ายกับไอคอนทางขวา
              border: selectedIndexes.includes(index),
            }}
          >
            <Card
              style={{ zIndex: 1000 }}
              sx={{
                backgroundColor: selectedIndexes.includes(index)
                  ? "rgba(255, 94, 67, 0.1)" // สีพื้นหลังเข้มเมื่อถูกเลือก
                  : "transparent", // พื้นหลังปกติ
                transition: "background-color 0.3s ease, border 0.3s ease",
              }}
            >
              {/* <CardHeader title={"กรมธรรม์เลขที่ : " + _.policy_no} sx={{ backgroundColor: 'rgb(255, 94, 67,0.3)' }} /> */}
              <CardHeader
                title={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CustomCheckbox
                      checked={selectedIndexes.includes(index)} // ทำการเช็คตาม selectedIndexes
                      onChange={(e) => {
                        // console.log("");
                        e.stopPropagation(); // หยุดไม่ให้เกิด event ที่ parent
                        // handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id); // ทำการคลิกเมื่อ checkbox ถูกเลือก
                      }}
                      sx={{ color: "rgba(255, 94, 67, 0.8)" }} // ปรับสี
                    />
                    <Box sx={{ marginLeft: "10px" }}>
                      {" "}
                      {/* เพิ่มระยะห่างระหว่าง checkbox และข้อความ */}
                      {"กรมธรรม์เลขที่ : " + _.policy_no}
                    </Box>
                  </Box>
                }
                sx={{
                  backgroundColor: "rgba(255, 94, 67, 0.3)", // ปรับให้ใช้งาน rgba ถูกต้อง
                }}
                titleTypographyProps={{
                  sx: {
                    fontFamily: '"Prompt-Thin", sans-serif !important',
                    fontSize: "15px",
                    color: "black",
                    marginLeft: "15px",
                  },
                }}
              />

              <CardContent>
                <Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                      <span className="font-size-label-pol">
                        ชื่อ นามสกุล หน้าบัตรเครดิต :
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                      <div
                        style={{
                          // display: 'flex',
                          flexDirection: "column", // ให้จัดเรียงข้อความในแนวตั้ง
                          whiteSpace: "normal", // ให้ตัดบรรทัดตามความยาวของเนื้อหา
                          wordBreak: "break-word", // ตัดคำเมื่อคำยาวเกินไป
                        }}
                      >
                        <span className="font-size-label-pol">
                          {`${_.card_holder_name}`}
                        </span>
                      </div>
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          หมายเลขบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {`${_.card_number}`}
                        </span>
                      </Grid>
                    </Grid> */}
{/* 
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                      <span className="font-size-label-pol">เดือน/ปี :</span>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                      <span className="font-size-label-pol">
                        {formatDate(_.card_expire_date || "-")}
                      </span>
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                      <span className="font-size-label-pol">
                        หมายเลขบัตร :
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                      <span className="font-size-label-pol">
                        {/* ******** */}
                        {`${_.card_number ? partialHide(_.card_number) : "-"
                          }`}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={11} sm={4} md={3} lg={3} xl={3}>
                      <span className="font-size-label-pol">
                        เดือน/ปี หมดอายุของบัตร :
                      </span>
                    </Grid>
                    <Grid item xs={11} sm={7} md={8} lg={8} xl={8}>
                      <span className="font-size-label-pol">
                        {/* ******** */}
                        {/* {`${_.card_expire_date || "-"}`} */}
                        {_.card_expire_date || "-"}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center", // จัดให้อยู่กึ่งกลางแนวนอน
                        alignItems: "center", // จัดให้อยู่กึ่งกลางแนวตั้ง
                      }}
                    >
                      {/* {selectedIndexes.includes(index) && (
                          <CheckCircleIcon
                            sx={{
                              color: 'rgba(255, 94, 67, 0.8)',
                            }}
                          />
                        )} */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                      <span className="font-size-label-pol">
                        ประเภทของบัตร :
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                      <span className="font-size-label-pol">
                        {/* ******** */}
                        {`${_.card_type
                          ? getCardTypeNameByCode(_.card_type)
                          : "-"
                          }`}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                      <span className="font-size-label-pol">
                        ธนาคารเจ้าของบัตรเครดิต :
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                      <span className="font-size-label-pol">
                        {/* ******** */}
                        {`${_.credit_bank_name === null
                          ? "-"
                          : _.credit_bank_name
                          }`}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={5} md={3} lg={4} xl={4}>
                      <span className="font-size-label-pol">
                        ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย :
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={7} md={8} lg={8} xl={8}>
                      <span className="font-size-label-pol">
                        {/* ******** */}
                        {`${_.card_relation == null ? "-" : _.card_relation}`}
                      </span>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            </Card>
            <Box></Box>
          </Box>
        ))}
      </CardContent>

    </Card>
  );
};

export default ScrollableCreditCard;
