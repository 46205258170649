const CurrencyFormater = (number) => {
  if (number == null || isNaN(number)) {
    return "NaN";
  }
  return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? "NaN";
};

const CurrencyFormaterRound = (number) => {
  if (number == null || isNaN(number)) {
    return "NaN";
  }
  const roundedNumber = Math.floor(number / 100) * 100;
  return roundedNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const CurrencyFormaterNonDec = (number) => {
  return Math.round(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? "NaN";
};

export { CurrencyFormater,CurrencyFormaterNonDec,CurrencyFormaterRound };
