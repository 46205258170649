import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import img_1 from "../../images/rabbitlife-logo.png";

const HeaderLogin = () => {
  return (
    <div className="top">
      <section className="sec-navbar">
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-2 nav-h">
          <a className="navbar-brand ml-3" href="#">
            <img src={img_1} height="48" alt="" />
          </a>
        </nav>
      </section>
    </div>
  );
};

export default HeaderLogin;
