import React, { useState, useContext } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import RegisterQuickTransfet from "./components/RegisterQuickTransfer";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import UserConsent from "./components/UserConsent";
import TransferContext, { TransferProvider } from "./TranferContextProvider";
import EnterCitizenId from "./components/EnterCitizenId";
import EnterOtp from "./components/EnterOTP";
import UpdateBank from "./components/UpdateBank";
import SelectDocumentAge from "./components/SelectDocumentAge";
import UploadFilePolicy from "./components/UploadFilePolicy";
import Completed from "./components/Completed";

const steps = 7;

const QuickTransfer = () => {
  const { activeStep, headerTitle } =
    useContext(TransferContext);

  return (
    <div>
      {/* <Header_Home /> */}
      <Header title={headerTitle} />
      <div className="container centered" style={{ marginTop: 40 }}>
        {activeStep === steps ? (
          <React.Fragment>
            <Completed />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && <RegisterQuickTransfet />}
            {activeStep === 1 && <UserConsent />}
            {activeStep === 2 && <EnterCitizenId />}
            {activeStep === 3 && <EnterOtp />}
            {activeStep === 4 && <UpdateBank />}
            {activeStep === 5 && <SelectDocumentAge />}
            {activeStep === 6 && <UploadFilePolicy />}
          </React.Fragment>
        )}

      </div>
    </div>
  );
};
const WrappedQuickTransfer = () => (
  <TransferProvider>
    <QuickTransfer />
  </TransferProvider>
);

export default WrappedQuickTransfer;


