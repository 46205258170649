import { Axios } from "../../../util";

const getPolicyInformationAPI = async (insureIdCard) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(
      `/api/policy/policyInformation?insureIdCard=${insureIdCard}`,
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const uploadFileOnedrive = async (formData) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(`/api/policy/uploadFileOnedrive`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};


export {
  getPolicyInformationAPI,
  uploadFileOnedrive
          };