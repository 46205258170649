import { Axios } from "../../../util";


const searchRequest = async (policy_number,insurer_name,startRequestDate, endRequestDate, startCloseDate, endCloseDate,type,status) => {
  try {
    const res = await Axios.get(`/api/request/getDatalist?policy_number=${policy_number}&insurer_name=${insurer_name}&startRequestDate=${startRequestDate}&endRequestDate=${endRequestDate}&startCloseDate=${startCloseDate}&endCloseDate=${endCloseDate}&type=${type}&status=${status}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const updateTaken = async (takenData) => {
  // ดึง first_name และ last_name จาก localStorage
  const firstName = localStorage.getItem('first_name');
  const lastName = localStorage.getItem('last_name');

  try {
      const res = await Axios.post('/api/request/update_taken', {
          taken: takenData,
          FirstName: firstName, // ใช้ค่าที่ดึงมาจาก localStorage
          LastName: lastName     // ใช้ค่าที่ดึงมาจาก localStorage
      }, {
          headers: {
              'Content-Type': 'application/json',
              'authorization': `Bearer ${localStorage.getItem('token_login')}`
          }
      });
      return res.data;
  } catch (error) {
      console.error("There was an error updating the taken data!", error);
      throw error;
  }
};

const updateCloseDate = async (closeData, firstName, lastName) => {
  try {
    const res = await Axios.post('/api/request/update', {
      close_date: closeData,
      firstName: firstName,
      lastName: lastName
    }, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('token_login')}`
      }
    });
    return res.data;
  } catch (error) {
    console.error("There was an error updating the close date!", error);
    throw error;
  }
};

const getdetail = async (id_detail,type) => {
  try {
    const res = await Axios.get(`/api/request/detail?id=${id_detail}&type=${type}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};




export {
  searchRequest,
  updateTaken,
  updateCloseDate,
  getdetail
          };