import React, { useContext, useEffect } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { StoreContext } from "./context/store";
import {
  getPolicyInformationAPI,
  getPolicyCouponAPI,
  verifyTokenAPI,
} from "./mainService/mainService";
import CryptoJS from 'crypto-js';

function PrivateRoute() {
  const token = localStorage.getItem("token_login") == null ? false : true;
  const username = localStorage.getItem("customer_id") == null ? false : true;
  const profile = localStorage.getItem("profile") == null ? false : true;
  const profileCheck =
    localStorage.getItem("profile") !== null && localStorage.getItem("profile");

  let naviate = useNavigate();

  const { infomationAccount, updateInformationAccount } =
    useContext(StoreContext);

  const handleDataInformation = async () => {
    let customerId = await decryptCardNumber(localStorage.getItem("customer_id"),process.env.REACT_APP_ENCRYPT_DATA);
    updateInformationAccount({ loadingInformation: true });
    // const resultInformation = await getPolicyInformationAPI(customerId);
    // console.log(resultInformation);
    // if (resultInformation.status) {
    //   const policyNo =
    //     resultInformation.data.policies.length > 0
    //       ? resultInformation.data.policies[0].policyNo
    //       : "";
    //   const resultCoupon = await getPolicyCouponAPI(policyNo);
    //   if (resultCoupon.status) {
    //     let data = {
    //       username: customerId,
    //       age: resultCoupon.data.insureAge ?? 0,
    //       loadingInformation: false,
    //     };
    //     updateInformationAccount(data);
    //   }
    // }
  };

  const handleVerifyToken = async () => {
    const result = await verifyTokenAPI();
    if (result.status) {
      if (profileCheck !== "POS") {
        await handleDataInformation();
      }
    } else {
      localStorage.clear();
      naviate("/")
    }
  };

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  useEffect(() => {
    handleVerifyToken()
  }, [naviate]);

  return <>{token ? <Outlet /> : <Navigate to="/" />}</>;
}

export default PrivateRoute;
