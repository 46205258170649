import { Axios } from "../../../util";


const searchBenefit = async (startDate, endDate, name, psnId,check) => {
  try {
    const res = await Axios.get(`/api/benefit/search-benefit?startDate=${startDate}&endDate=${endDate}&name=${name}&psnId=${psnId}&check=${check}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};
const saveBenefit = async (cusUuid, cuscfBank, cuscfBanknum, cusCheck) => {
  try {
    const res = await Axios.post(`/api/benefit/save-benefit?cus_uuid=${cusUuid}&cuscf_bank=${cuscfBank}&cuscf_banknum=${cuscfBanknum}&cus_check=${cusCheck}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const downloadExcel = async (startDate, endDate, name, psnId,check) => {
  try {

    const response = await Axios.get(`/api/benefit/excel?startDate=${startDate}&endDate=${endDate}&name=${name}&psnId=${psnId}&check=${check}`, { responseType: 'blob' });

    // สร้าง URL จาก Blob เพื่อให้ดาวน์โหลดไฟล์ได้
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Report.xlsx'); // กำหนดชื่อไฟล์
    document.body.appendChild(link);
    link.click();
    link.remove(); // ลบ link หลังจากดาวน์โหลด
  } catch (error) {
    console.error('Error downloading the Excel file:', error);
  }
};



export {
  searchBenefit,
  saveBenefit,
  downloadExcel
};