import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import Axios from "../../../util/Axios";

export const getMenuRequest = async () => {
  try {
    const res = await Axios.get(`/api/request/getMenuRequest`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const getMenuLetter = async () => {
  try {
    const res = await Axios.get(`/api/request/getMenuLetter`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};
