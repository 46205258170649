import React from "react";
import { TextField, InputLabel, IconButton } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CircularProgress from "@mui/material/CircularProgress";

export const CustomInputTextEmail = (props) => {
  const {
    id,
    value,
    onChange,
    label,
    placeholder,
    disabled,
    loading,
    error,
    errorText,
    ...other
  } = props;

  return (
    <>
      {label ? (
        <InputLabel htmlFor={id} className="label text-black">
          {label}
        </InputLabel>
      ) : null}
      <TextField
        InputProps={{
          sx: { borderRadius: 16 },
          endAdornment: loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : value ? (
            disabled ? undefined : (
              <IconButton
                aria-label="clear-input"
                sx={{ marginBottom: "0px !important;" }}
                onClick={() => onChange({ target: { value: "" } })}
              >
                <ClearRoundedIcon />
              </IconButton>
            )
          ) : undefined,
        }}
        sx={{
          "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "#ECE9E9A6;",
          },
        }}
        variant="outlined"
        size="small"
        fullWidth
        id={id}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
        error={error}
        helperText={error ? errorText || "รูปแบบอีเมลไม่ถูกต้อง" : undefined}
        {...other}
      />
    </>
  );
};
