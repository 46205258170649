import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Link, Box } from "@mui/material";
import img_1 from "../../images/rabbitlife-logo.png";
import lineIcon from "../../images/lineIcon.png";
import facebookIcon from "../../images/facebookIcon.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";

const Footer_home = () => {
  useEffect(() => {
    getProduct();
    getTel();
  }, []);

  const [cusTel, setCusTel] = useState("");
  const [product1, setProduct1] = useState("");
  const [product2, setProduct2] = useState("");
  const [product3, setProduct3] = useState("");
  const [product4, setProduct4] = useState("");
  const [product5, setProduct5] = useState("");
  const [product6, setProduct6] = useState("");
  const [product7, setProduct7] = useState("");
  const [product8, setProduct8] = useState("");
  const [product9, setProduct9] = useState("");
  const [product10, setProduct10] = useState("");
  const [product11, setProduct11] = useState("");
  const [product12, setProduct12] = useState("");
  const [product13, setProduct13] = useState("");
  const [product14, setProduct14] = useState("");
  const [product15, setProduct15] = useState("");
  const [product16, setProduct16] = useState("");
  const [product17, setProduct17] = useState("");
  const [product18, setProduct18] = useState("");
  const [product19, setProduct19] = useState("");
  const [product20, setProduct20] = useState("");
  const [product21, setProduct21] = useState("");
  const [product22, setProduct22] = useState("");
  const [product23, setProduct23] = useState("");
  const [product24, setProduct24] = useState("");
  const [product25, setProduct25] = useState("");
  const [product26, setProduct26] = useState("");
  const [product27, setProduct27] = useState("");
  const [product28, setProduct28] = useState("");
  const [product29, setProduct29] = useState("");

  const setProducts = [
    setProduct1,
    setProduct2,
    setProduct3,
    setProduct4,
    setProduct5,
    setProduct6,
    setProduct7,
    setProduct8,
    setProduct9,
    setProduct10,
    setProduct11,
    setProduct12,
    setProduct13,
    setProduct14,
    setProduct15,
    setProduct16,
    setProduct17,
    setProduct18,
    setProduct19,
    setProduct20,
    setProduct21,
    setProduct22,
    setProduct23,
    setProduct24,
    setProduct25,
    setProduct26,
    setProduct27,
    setProduct28,
    setProduct29
  ];

  const theme = createTheme({
    typography: {
      fontFamily: "'Prompt-Thin', sans-serif", // เปลี่ยนเป็นฟอนต์ Prompt
    },
  });

  const getProduct = async () => {
    const result = await getParameterAPI(24);
    const payload = result.payload;

    payload.forEach((item, index) => {
      if (setProducts[index]) {
        setProducts[index](item.variable1 || "");
      }
    });
  };
  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    setCusTel(cusTel.variable1);
  };

  const themes = useTheme();
  const isSmallScreen = useMediaQuery(themes.breakpoints.down("lg"));
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "#f8f9fd",
          color: "#333",
          padding: "40px 40px", // บน 40px, ขวา 30px, ล่าง 20px, ซ้าย 50px
          lineHeight: 2,
          fontSize: "14px",
          "@media (max-width: 600px)": {
            padding: "20px", // บน-ขวา-ล่าง-ซ้าย เท่ากันในจอเล็ก
          },
        }}
      >
        <Grid container spacing={4}>
          {/* ข้อมูลบริษัท */}
          <Grid item xs={12} sm={1.5}></Grid>
          <Grid item xs={12} sm={10.5}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={img_1}
                alt="บริษัท แรบบิท ประกันชีวิต จำกัด (มหาชน)"
                style={{ width: "200px", height: "auto" , marginLeft: "15px"}}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={1.5}></Grid>
          <Grid item xs={12} sm={3} style={{ marginLeft: "15px" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "0px", color: "#fb5e3f" }}
            >
              บริษัท แรบบิท ประกันชีวิต จำกัด (มหาชน)
            </Typography>
            <Typography>
              เลขที่ 175 อาคารสาธรซิตี้ ทาวเวอร์ ชั้น 1/1 และ 2/1 ถนนสาทรใต้
              แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพมหานคร 10120
            </Typography>
            <br />
            <Link
              href={product27}
              underline="hover"
              sx={{ fontWeight: "bold", fontSize: "20px", color: "#fb5e3f" }}
            >
              Call 02-648-3600
            </Link>
            <br />
            <Typography>
              Email:{" "}
              <Link
                href={product24}
                underline="hover"
                sx={{ color: "#fb5e3f" }}
              >
                cs@rabbitlife.co.th
              </Link>
            </Typography>
            <Typography>
              แจ้งอุบัติเหตุฉุกเฉิน 24 ชั่วโมง:{" "}
              <Link
                href={product28}
                underline="hover"
                sx={{ color: "#fb5e3f" }}
              >
                02-494-7005
              </Link>
            </Typography>
            <Typography>
              LINE:{" "}
              <Link
                href={product25}
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#fb5e3f" }}
              >
                @rabbit_life
              </Link>
            </Typography>

            <br />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ marginRight: "10px" }}>
                <a href={product26} target="_blank" rel="noopener noreferrer">
                  <img
                    src={facebookIcon}
                    alt="Facebook"
                    style={{ width: "30px", height: "auto" }}
                  />
                </a>
              </Box>
              <Box>
                <a href={product25} target="_blank" rel="noopener noreferrer">
                  <img
                    src={lineIcon}
                    alt="LINE"
                    style={{ width: "30px", height: "auto" }}
                  />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={2.5}
            sx={{
              marginLeft: { xs: "15px", sm: 0 }, 
              marginRight: { xs: "-15px", sm: 0 } 
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              ผลิตภัณฑ์
            </Typography>
            <Link
              href={product1}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ประกันเพื่อการลงทุน
            </Link>
            <Link
              href={product2}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ประกันบำนาญ
            </Link>
            <Link
              href={product3}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ประกันลดหย่อนภาษี
            </Link>
            <Link
              href={product4}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ประกันอุบัติเหตุ
            </Link>
            <Link
              href={product5}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ประกันสุขภาพ
            </Link>
            <Link
              href={product6}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ประกันคุ้มครองชีวิต
            </Link>
            <Link
              href={product7}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ประกันออนไลน์
            </Link>
            <Link
              href={product8}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ประกันกลุ่ม
            </Link>
            <Link
              href={product9}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              กองทุนรวม
            </Link>
          </Grid>

          {/* บริการ */}
          <Grid item xs={6} sm={2.5}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              บริการ
            </Typography>
            <Link
              href={product10}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              บริการผู้ถือกรมธรรม์
            </Link>
            <Link
              href={product11}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ค้นหาตัวแทนประกันชีวิต หรือ นายหน้าประกันชีวิต
            </Link>
            <Link
              href={product12}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              ดาวน์โหลดแบบฟอร์ม
            </Link>
            <Link
              href={product13}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              โรงพยาบาลในเครือข่าย
            </Link>
            <Link
              href={product14}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              เรียกร้องสินไหม
            </Link>
            <Link
              href={product15}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              วิธีชำระเงิน
            </Link>
            <Link
              href={product16}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              เรื่องร้องเรียน
            </Link>
            <Link
              href={product17}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              วิธีแจ้งเบาะแส
            </Link>
          </Grid>

          {/* ข้อมูลเพิ่มเติม */}
          <Grid item xs={12} sm={2} style={{ marginLeft: "15px" }}>
            {/* <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              คำถามที่พบบ่อย
            </Typography> */}
            <Link
              href={product29}
              underline="none"
              target="_blank"
              sx={{ color: "black", display: "block", lineHeight: "1.5" }}
            >
              คำถามที่พบบ่อย
            </Link>
            <Link
              href={product18}
              underline="none"
              target="_blank"
              sx={{ color: "black", display: "block", lineHeight: "1.5" }}
            >
              บทความที่น่าสนใจ
            </Link>
            <Link
              href={product19}
              underline="none"
              target="_blank"
              sx={{ color: "black", display: "block", lineHeight: "1.5" }}
            >
              เกี่ยวกับเรา
            </Link>
            <Link
              href={product20}
              underline="none"
              target="_blank"
              sx={{ color: "black", display: "block", lineHeight: "1.5" }}
            >
              ติดต่อเรา
            </Link>
            <Link
              href={product21}
              underline="none"
              target="_blank"
              sx={{ color: "black", display: "block", lineHeight: "1.5" }}
            >
              สำหรับตัวแทน
            </Link>
            <Link
              href={product22}
              underline="none"
              target="_blank"
              sx={{ color: "black", display: "block", lineHeight: "1.5" }}
            >
              ข่าวสารและกิจกรรม
            </Link>
          </Grid>
          <Grid item xs={12} sm={1.5}></Grid>
          <Grid item xs={12} sm={5} sx={{ paddingLeft: 0 }}>
            <Box
              sx={{
                borderBottom: "1px solid #e2e8ff",
                marginBottom: "8px",
                marginLeft: "-16px",
              }}
            ></Box>
            <Link
              href={product23}
              underline="none"
              target="_blank"
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              นโยบายความเป็นส่วนตัว
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4.5}
            sx={{ textAlign: "right", paddingLeft: 0 }}
          >
            <Box
              sx={{
                borderBottom: "1px solid #e2e8ff",
                marginBottom: "8px",
                marginLeft: "-32px",
              }}
            ></Box>
            <Typography
              sx={{ color: "gray", display: "block", lineHeight: "1.5" }}
            >
              COPYRIGHT © Rabbit Life Insurance ALL RIGHTS RESERVED.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Footer_home;
