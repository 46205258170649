import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSwipeable } from "react-swipeable";
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import { styled } from "@mui/system";
import { CardHeader } from "@mui/material";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
// CSS
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./edit_name_v2.css";
// component
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import PopUp from "../Component/Popup";
import { CustomCarousel } from "../Component/CustomCarousel";
import { CustomInputText } from "../Component/CustomInputText";
import { CustomInputAutocomplete } from "../Component/CustomInputAutocomplete";
import { CustomInputFile } from "../Component/CustomInputFile";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import FileUploadWithPreviewName from "./FileUploadWithPreviewName";
// services
import {
  getPersonalNameAPI,
  getUserProfileAPI,
  getPersonalPrefixAPI,
  updatePersonalImageAPI,
  updatePersonalNameAPI,
} from "./PersonalService/personalService";
// icon
import icon_document from "../../Icon/document_icon.png";
import { Carousel } from "react-responsive-carousel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "react-modal";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import iconSearch from "../../images/icon-search-2.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Modal as BootstrapModal } from "react-bootstrap";
import greenCheck from "../../Icon/green-circle-check.png";
import remove from "../../images/remove.png";

const EditName2 = (props) => {
  let navigate = useNavigate();

  const refCarousel = useRef(null);

  const [nameStep, setNameStep] = useState(1);
  const [nameData, setNameData] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [prefixOptions, setPrefixOptions] = useState();
  const [loading, setLoading] = useState(true); // for get profile, get name, save edit, save image
  const [loadingPrefix, setLoadingPrefix] = useState(true); // for get Prefix

  const fileDescriptions = [
    "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน",
    "สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)",
    "สำเนาเอกสารการเปลี่ยนแปลงชื่อ-สกุล จากทางราชการ (ถ้ามี)",
  ];
  const [files, setFiles] = useState(Array(fileDescriptions.length).fill(null));

  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [middleName, setMiddleName] = useState();
  const [prefix, setPrefix] = useState();
  const [inputErrors, setInputErrors] = useState([]);

  const [file1, setFile1] = useState();
  const [fileName1, setFileName1] = useState();
  const [file2, setFile2] = useState();
  const [fileName2, setFileName2] = useState();
  const [file3, setFile3] = useState();
  const [fileName3, setFileName3] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [clickPre, setClickPre] = useState();
  const [clickForward, setClickForward] = useState();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [activeDataModal, setActiveDataModal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [searchBasePolicy, setSearchBasePolicy] = useState("");
  const [option, setOption] = useState([]);
  const handleOpenModal = () => setOpenModal(true);
  const [completedModal, setCompletedModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [toggleImg, setToggleImg] = useState(false);
  const cardRef = React.useRef(null);
  const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
  const [isChecked, setIsChecked] = useState(false); // สถานะของ CheckAll
  const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก
  const [idnvFlag, setIdnvFlag] = useState(
    localStorage.getItem("idnvFlag") || "N"
  );
  // ----- function for Step 1 update name -----
  const getPersonalName = () => {
    setLoading(true);
    // setErrorNameData(false);
    getPersonalNameAPI()
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let option = [];
          const data = [
            {
              policy_no: "123456",
              title: "นาย",
              first_name: "จิรภัทร",
              last_name: "ชูกลีบ",
              middle_name: "",
            },
            {
              policy_no: "342455",
              title: "นาย",
              first_name: "ทศพล",
              last_name: "ชูกลีบ",
              middle_name: "",
            },
            {
              policy_no: "565766",
              title: "นาย",
              first_name: "เกศร",
              last_name: "ชูกลีบ",
              middle_name: "",
            },
          ];
          res.payload.map((item, idx) => {
            let dataTmp = {
              label: item.policy_no,
              id: idx,
            };
            option.push(dataTmp);
          });
          setOption(option);
          setNameData(res.payload);
          // setNameData(res.payload);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // setErrorNameData(true);
        // setOpenErrorWarning(true);
        // alert("ไม่สามารถเข้าถึงข้อมูลได้");
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  const getPrefix = () => {
    setLoadingPrefix(true);
    // setErrorPrefixData(false);
    getPersonalPrefixAPI()
      .then((res) => {
        setLoadingPrefix(false);
        if (res.success) {
          setPrefixOptions(res.payload);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingPrefix(false);
        // setErrorPrefixData(true);
        // setOpenErrorWarning(true);
        // alert("ไม่สามารถเข้าถึงข้อมูลได้");
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  // ----- function for Step 2 upload file -----
  const validateName = () => {
    const errors = [];
    if (!name) errors.push("input-name");
    if (!surname) errors.push("input-surname");
    // if (!middleName) errors.push("input-middle-name");
    if (!prefix) errors.push("input-prefix");
    setInputErrors(errors);
    if (errors.length > 0) {
      setToggleImg(false);
      setTextModal("กรุณากรอกข้อมูลให้ครบถ้วน");
      setCompletedModal(true);
    }
    return errors;
  };

  // const validateImage = () => {
  //   let error = false;
  //   let errorMessage = "";
  //   if (!file1 || file1.length === 0 || !file2 || file2.length === 0) {
  //     error = true;
  //     errorMessage =
  //       "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ";
  //   } else {
  //     const fileToValidateType = [];
  //     if (file1 && file1[0]) fileToValidateType.push(file1);
  //     if (file2 && file2[0]) fileToValidateType.push(file2);
  //     if (file3 && file3[0]) fileToValidateType.push(file3);

  //     fileToValidateType.forEach((file) => {
  //       let type = file[0].type;
  //       if (
  //         !["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(
  //           type
  //         )
  //       ) {
  //         error = true;
  //         errorMessage = "กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น";
  //       }
  //     });
  //   }
  //   if (error) {
  //     setToggleImg(false);
  //     setTextModal(errorMessage);
  //     setCompletedModal(true);
  //   }
  //   return error;
  // };

  const validateImage = () => {
    let error = false;
    let errorMessage = "";
    let indexFile = 0;

    for (const f of files) {
      // อนุญาตให้ index ที่เป็น 2 (ตำแหน่งที่ 3) เป็น null ได้
      if (f == null && indexFile !== 2) {
        error = true;
        errorMessage =
          "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ";
        break;
      }
      indexFile += 1;
    }

    // ถ้าไม่มี error ในขั้นต้น ให้ตรวจสอบประเภทไฟล์ต่อไป
    // if (!error) {
    //   for (const file of files) {
    //     if (file && file.type) { // ตรวจสอบว่า file ไม่เป็น null/undefined
    //       const type = file.type;
    //       if (!["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(type)) {
    //         error = true;
    //         errorMessage = "กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น";
    //         break;
    //       }
    //     } else if (indexFile !== 2) { // ตรวจสอบว่าถ้าเป็น null และไม่ใช่ index 2
    //       error = true;
    //       errorMessage = "ไฟล์บางไฟล์ไม่มีข้อมูลประเภท กรุณาอัพโหลดใหม่";
    //       break;
    //     }
    //   }
    // }

    if (error) {
      setToggleImg(false);
      setTextModal(errorMessage);
      setCompletedModal(true);
    }

    return error;
  };

  const removeInputError = (inputId) => {
    setInputErrors((errors) => errors.filter((err) => err !== inputId));
  };

  // const saveName = () => {
  //   setLoading(true);
  //   // setErrorSaveNameData(false);
  //   const errorsName = validateName();
  //   const errorImage = validateImage();
  //   if (
  //     selectedName.length > 0 &&
  //     errorsName.length === 0 &&
  //     errorImage === false
  //   ) {
  //     const payload = { policies: [] };

  //     listData.forEach((item) => {
  //       payload.policies.push({
  //         policy_no: item.policyNo,
  //         first_name: item.first_name,
  //         last_name: item.last_name,
  //         middle_name: item.middle_name,
  //         title: item.title.description,

  //         old_title: item.old_title,
  //         old_first_name: item.old_first_name,
  //         old_last_name: item.old_last_name,
  //         old_middle_name: item.old_middle_name,
  //       });
  //     });
  //     // console.log("payload to submit >>>", payload);
  //     updatePersonalNameAPI(payload)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.success) {
  //           saveImage();
  //         } else {
  //           console.error("fail Save Name Data", res);
  //           setToggleImg(false);
  //           setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //           setCompletedModal(true);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setLoading(false);
  //         setToggleImg(false);
  //         setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //         setCompletedModal(true);
  //       });
  //   } else {
  //     setLoading(false);
  //   }
  // };
  // const saveImage = () => {
  //   setLoading(true);
  //   // setErrorSaveImageData(false);

  //   const errorImage = validateImage();
  //   if (selectedName.length > 0 && errorImage === false) {
  //     let policy = "";
  //     selectedName.forEach((item, index) => {
  //       if (index === 0) policy = item.policy_no;
  //       else policy = policy + "," + item.policy_no;
  //     });
  //     const payload = new FormData();
  //     payload.append("files", file1[0], fileName1);
  //     payload.append("files", file2[0], fileName2);
  //     payload.append("files", file3[0], fileName3);
  //     payload.append("policyNo", policy);
  //     payload.append("type", "4");

  //     // console.log("payload to submit >>>", payload);
  //     // for (var [key, value] of payload.entries()) {
  //     //   console.log("key, value to submit >>>", key, value);
  //     // }

  //     updatePersonalImageAPI(payload)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.success) {
  //           setToggleImg(true);
  //           setTextModal(
  //             "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
  //           );
  //           setCompletedModal(true);
  //         } else {
  //           setToggleImg(false);
  //           setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //           setCompletedModal(true);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setLoading(false);
  //         setToggleImg(false);
  //         setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //         setCompletedModal(true);
  //       });
  //   } else {
  //     setLoading(false);
  //   }
  // };

  const saveData = async () => {
    try {
      setLoading(true);

      // ตรวจสอบความถูกต้องของชื่อและรูปภาพ
      // const errorsName = validateName();
      // const errorImage = validateImage();

      // if (
      //   selectedName.length > 0 &&
      //   errorsName.length === 0
      //   // &&
      //   // errorImage === false
      // ) {
      // เตรียม Payload สำหรับบันทึกชื่อ
      const namePayload = { policies: [] };
      listData.forEach((item) => {
        namePayload.policies.push({
          policy_no: item.policyNo,
          first_name: item.first_name,
          last_name: item.last_name,
          middle_name: item.middle_name,
          title: item.title.description,

          old_title: item.old_title,
          old_first_name: item.old_first_name,
          old_last_name: item.old_last_name,
          old_middle_name: item.old_middle_name,
        });
      });

      // เรียก API บันทึกชื่อ
      const nameResponse = await updatePersonalNameAPI(namePayload);

      if (!nameResponse.success) {
        console.error("fail Save Name Data", nameResponse);
        throw new Error("ไม่สามารถบันทึกข้อมูลชื่อได้");
      }
      // console.log(nameResponse);
      // if (idnvFlag === 'N') {
      // เตรียม Payload สำหรับบันทึกรูปภาพ
      let policy = listData.map((item) => item.policy_no).join(",");
      const imagePayload = new FormData();
      files.forEach((f, index) => {
        console.log(index);
        if (index < 2) { 
          imagePayload.append("files", f, f.name);
          imagePayload.append("codeFile", index);
        } else {
          console.log(f);
          if (f != null) {
            imagePayload.append("files", f, f.name);
            imagePayload.append("codeFile", index);
          }
        }
      });

      // imagePayload.append("files", file1[0], fileName1);
      // imagePayload.append("files", file2[0], fileName2);
      // imagePayload.append("files", file3[0], fileName3);
      imagePayload.append("listID", nameResponse.result);
      imagePayload.append("type", "4");

      // เรียก API บันทึกรูปภาพ
      const imageResponse = await updatePersonalImageAPI(imagePayload);

      if (!imageResponse.success) {
        throw new Error("ไม่สามารถบันทึกรูปภาพได้");
      }
      // }

      // ถ้าบันทึกสำเร็จทั้งหมด
      setToggleImg(true);
      setTextModal(
        "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
      );
      // }
      // else {
      //   throw new Error("ข้อมูลไม่ถูกต้อง");
      // }
    } catch (error) {
      console.error(error);
      setToggleImg(false);
      setTextModal(error.message || "ไม่สามารถบันทึกข้อมูลได้");
    } finally {
      setLoading(false);
      setCompletedModal(true);
    }
  };

  useEffect(() => {
    // console.log(nameStep);
    // console.log(idnvFlag);
    // check user profile
    setLoading(true);
    getUserProfileAPI()
      .then((res) => {
        setLoading(false);
        if (res.success == "true") {
          if (res.payload.profile == "POS2") {
            // window.location.href = "/home";
            navigate("/home");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // setErrorProfileData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
    // get Personal name
    getPersonalName();
    // get prefix data for dropdown
    getPrefix();

    return () => {
      // console.log("useEffect return unmount >>>");
    };
  }, []);

  useEffect(() => {
    if (nameData.length > 0) {
      setUpSelectName(0);
    }
  }, [nameData]);

  const handleOwlChange = async (e) => {
    setActiveIndex(e);
    setActiveDataModal(e);
    setUpSelectName(e);
    // updateDataPolicyActive(e);
  };

  const breakPoints = [{ width: 1, itemsToShow: 1 }];

  const setUpSelectName = (index) => {
    setSelectedName((prev) => {
      let findName = prev.find(
        (i) => i.policy_no === nameData[index].policy_no
      );
      if (findName) {
        return prev.filter((ii) => ii.policy_no !== findName.policy_no);
      } else {
        return [...prev, nameData[index]];
      }
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchBasePolicy("");
  };

  const filteredOptions = option.filter((item) => {
    return searchBasePolicy.trim() === ""
      ? true
      : item.label.toLowerCase().includes(searchBasePolicy.toLowerCase());
  });

  const handleConfrimSearch = () => {
    setActiveIndex(activeDataModal);
    // updateDataPolicyActive(activeDataModal);
    handleCloseModal();
    setUpSelectName(activeDataModal);
  };

  const renderOptionRow = (val, idx) => {
    const isActive = activeDataModal === idx;

    return (
      <div key={idx}>
        <Row
          onClick={!isActive ? () => handleSelectSearch(idx) : undefined}
          style={isActive ? { backgroundColor: "#FF5E43" } : {}}
          className={`gap-1 border-top border-bottom border-1 rounded-1 ${
            isActive ? "text-white" : "text-danger pointer"
          }`}
        >
          <Col className="px-3 d-flex flex-row justify-content-between">
            <span>{val.label}</span>
            {isActive && (
              <ArrowCircleLeftOutlinedIcon style={{ color: "white" }} />
            )}
          </Col>
        </Row>
      </div>
    );
  };

  const handleSelectSearch = (idx) => {
    setActiveDataModal(idx);
  };

  // new Card

  const handleBoxClick = (
    index,
    policyNoVal,
    old_first_name,
    old_last_name,
    old_title,
    old_middle_name
  ) => {
    // if (cardRef.current) {
    //   cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
    // console.log(cardRef);
    setSelectedIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        // ถ้าถูกเลือกแล้ว ให้ลบออกจาก array
        removeItemByPolicyNo(policyNoVal);
        return prevIndexes.filter((i) => i !== index);
      } else {
        // ถ้ายังไม่ถูกเลือก ให้เพิ่มเข้าไปใน array

        const updatedList = {
          policyNo: policyNoVal,
          first_name: name,
          last_name: surname,
          middle_name: middleName,
          title: prefix,

          old_first_name: old_first_name,
          old_last_name: old_last_name,
          old_title: old_title,
          old_middle_name: old_middle_name,
        };
        addItem(updatedList);
        return [...prevIndexes, index];
      }
    });
  };

  const addItem = (newItem) => {
    setListData((prevListData) => [...prevListData, newItem]);
  };

  const removeItemByPolicyNo = (policyNo) => {
    setListData((prevListData) =>
      prevListData.filter((item) => item.policyNo !== policyNo)
    );
  };

  const removeAllItems = () => {
    setListData([]); // ตั้งค่า listData ให้เป็น array ว่าง
    setSelectedIndexes([]); // เคลียร์ selectedIndexes
  };

  const handleCheckAll = () => {
    // console.log("nameDatanameDatanameDatanameDatanameData");

    // console.log(nameData);
    const updatedList = nameData.map((item) => ({
      policyNo: item.policy_no,
      first_name: name,
      last_name: surname,
      middle_name: middleName || "",
      title: prefix,

      old_first_name: item.first_name,
      old_last_name: item.last_name,
      old_title: item.title,
      old_middle_name: item.middle_name,
    }));

    setListData(updatedList);
    // if (isChecked) {
    //   removeAllItems(); // ลบข้อมูลทั้งหมด
    // } else {
    //   const allIndexes = emailData.map((_, index) => index); // สร้าง array ของทุก index
    //   setSelectedIndexes(allIndexes);

    //   // setDataInformation(updatedList) // ตั้งค่า listData ให้มีข้อมูลทั้งหมด
    // }
    // setIsChecked(!isChecked); // เปลี่ยนสถานะ CheckAll
  };

  const CustomCheckbox = styled(Checkbox)({
    color: "rgba(255, 94, 67, 0.5)",
    "&.Mui-checked": {
      color: "#FF5E43",
    },
    marginRight: "15px",
  });

  const ScrollableCard = () => {
    const handlers = useSwipeable({
      onSwiped: (eventData) => {
        if (cardRef.current) {
          cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
        }
      },
      delta: 10, // กำหนดความไวต่อการสไวป์
      trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    });

    if (listData.length > 0) {
      // console.log(listData);
      // setFlgCanNext(true)
    }

    return (
      <Card
        sx={{
          maxHeight: "440px",
          overflowY: "hidden",
          border: "1px solid rgba(255, 94, 67, 0.3)", // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: "12px", // ความโค้งของขอบ
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        ref={cardRef}
        {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >
        <CardContent
          sx={{
            cursor: "pointer",
            overflowY: "auto", // เปิดการเลื่อนในแนวตั้ง
            boxSizing: "border-box",
          }}
        >
          {/* <Box>
            <FormControlLabel
              control={<CustomCheckbox onClick={handleCheckAll} checked={isChecked} />}
              label={<Typography style={{ fontSize: '20px' }}>ทุกกรมธรรม์</Typography>}
            />
          </Box> */}

          {nameData.map((_, index) => (
            <Box
              key={index}
              // onClick={() => handleBoxClick(index, _.policy_no, _.card_holder_name, _.card_number, _.card_expire_date, _.card_type, _.credit_bank_name, _.premium_amount, _.next_premium_due,card_relation)}
              sx={{
                marginBottom: "15px",
                padding: "10px",
                transition: "background-color 0.3s ease, border 0.3s ease",
                cursor: "pointer",
                alignItems: "center", // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
                justifyContent: "space-between", // แยกข้อความทางซ้ายกับไอคอนทางขวา
                border: selectedIndexes.includes(index),
              }}
            >
              <Card
                style={{ zIndex: 1000 }}
                sx={{
                  backgroundColor: selectedIndexes.includes(index)
                    ? "rgba(255, 94, 67, 0.1)" // สีพื้นหลังเข้มเมื่อถูกเลือก
                    : "transparent", // พื้นหลังปกติ
                  transition: "background-color 0.3s ease, border 0.3s ease",
                }}
              >
                {/* <CardHeader title={"กรมธรรม์เลขที่ : " + _.policy_no} sx={{ backgroundColor: 'rgb(255, 94, 67,0.3)' }} /> */}

                <CardHeader
                  title={"กรมธรรม์เลขที่ : " + _.policy_no}
                  sx={{
                    backgroundColor: "rgba(255, 94, 67, 0.3)", // ปรับให้ใช้งาน rgba ถูกต้อง
                  }}
                  titleTypographyProps={{
                    sx: {
                      fontFamily: '"Prompt-Thin", sans-serif !important',
                      fontSize: "15px",
                      color: "black",
                      marginLeft: "15px",
                      // width: '50px !important',
                    },
                  }}
                />
                <CardContent>
                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          คำนำหน้าชื่อ :
                        </span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <div
                          style={{
                            // display: 'flex',
                            flexDirection: "column", // ให้จัดเรียงข้อความในแนวตั้ง
                            whiteSpace: "normal", // ให้ตัดบรรทัดตามความยาวของเนื้อหา
                            wordBreak: "break-word", // ตัดคำเมื่อคำยาวเกินไป
                          }}
                        >
                          <span className="font-size-label-pol">
                            {`${_.title}`}
                          </span>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ชื่อ - นามสกุล:{" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {`${_.first_name} ${_.last_name}`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">ชื่อกลาง :</span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {_.middle_name}
                        </span>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
              <Box></Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  const styleDrive = {
    py: 0,
    width: "100%",
    maxWidth: "auto",
    // borderRadius: 2,
    // border: '1px solid',
    borderColor: "divider",
    backgroundColor: "background.paper",
  };

  const ScrollableCardInformation = () => {
    const cardRef = React.useRef(null);

    const handlers = useSwipeable({
      onSwiped: (eventData) => {
        if (cardRef.current) {
          cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
        }
      },
      delta: 10, // กำหนดความไวต่อการสไวป์
      trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    });

    return (
      <Card
        sx={{
          maxHeight: "440px",
          overflowY: "hidden",
          border: "1px solid rgba(255, 94, 67, 0.3)", // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: "12px", // ความโค้งของขอบ
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        ref={cardRef}
        {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >
        <CardContent
          sx={{
            cursor: "pointer",
            overflowY: "auto", // เปิดการเลื่อนในแนวตั้ง
            boxSizing: "border-box",
          }}
        >
          {listData.length > 0 && (
            <Box
              key={0}
              // onClick={() => handleBoxClick(0, listData[0].policy_no, listData[0].card_holder_name, listData[0].card_number, listData[0].card_expire_date, listData[0].card_type, listData[0].credit_bank_name, listData[0].premium_amount, listData[0].next_premium_due, card_relation)}
              sx={{
                marginBottom: "15px",
                padding: "10px",
                transition: "background-color 0.3s ease, border 0.3s ease",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "space-between",
                border: selectedIndexes.includes(0),
              }}
            >
              <Card
                style={{ zIndex: 1000 }}
                sx={{
                  backgroundColor: selectedIndexes.includes(0)
                    ? "rgba(255, 94, 67, 0.1)"
                    : "transparent",
                  transition: "background-color 0.3s ease, border 0.3s ease",
                }}
              >
                <List sx={styleDrive}>
                  <ListItem>
                    <ListItemText
                      primary="ข้อมูลใหม่"
                      style={{ marginLeft: "10px" }}
                    />
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </List>

                <CardContent>
                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          คำนำหน้าชื่อ :
                        </span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <div
                          style={{
                            flexDirection: "column",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          <span className="font-size-label-pol">
                            {`${listData[0].title.description}`}
                          </span>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ชื่อ - นามสกุล:{" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {`${listData[0].first_name} ${listData[0].last_name}`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">ชื่อกลาง :</span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {listData[0].middle_name}
                        </span>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>

                <List sx={styleDrive}>
                  <ListItem>
                    <ListItemText
                      primary="ข้อมูลเก่า"
                      style={{ marginLeft: "10px" }}
                    />
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </List>

                <CardContent>
                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          คำนำหน้าชื่อ :
                        </span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <div
                          style={{
                            flexDirection: "column",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          <span className="font-size-label-pol">
                            {`${listData[0].old_title}`}
                          </span>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ชื่อ - นามสกุล:{" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {`${listData[0].old_first_name} ${listData[0].old_last_name}`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">ชื่อกลาง :</span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {listData[0].old_middle_name}
                        </span>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
              <Box></Box>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="edit-name">
      {loading ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      ) : null}
      <Header_Home />
      <Header title="เปลี่ยนชื่อ - นามสกุล" />
      <div className="container-footer-space">
        <section
          className="update-receiving-address"
          style={{ display: nameStep === 1 ? "block" : "none" }}
        >
          <div className="pb-2">
            <img
              className="name-document-icon"
              src={icon_document}
              alt="name-document-icon"
            />
            <span className="bold">{"ชื่อ - นามสกุล"}</span>
          </div>
          <div className="px-4">
            <CustomInputAutocomplete
              id="input-prefix"
              label="คำนำหน้าชื่อ"
              placeholder="กรุณาเลือก"
              value={prefix}
              onChange={(value) => {
                setPrefix(value);
                removeInputError("input-prefix");
              }}
              options={prefixOptions || []}
              getOptionLabel={(option) => option.description}
              isOptionEqualToValue={(option, value) =>
                option.description === value.description
              }
              loading={loadingPrefix}
              error={inputErrors.includes("input-prefix")}
            />
            <CustomInputText
              id="input-name"
              label="ชื่อ"
              placeholder="ชื่อจริง"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                removeInputError("input-name");
              }}
              error={inputErrors.includes("input-name")}
            />
            <CustomInputText
              id="input-surname"
              label="นามสกุล"
              placeholder="นามสกุล"
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
                removeInputError("input-surname");
              }}
              error={inputErrors.includes("input-surname")}
            />
            <CustomInputText
              id="input-middle-name"
              label="ชื่อกลาง"
              placeholder="ชื่อกลาง (ถ้ามี)"
              value={middleName}
              onChange={(e) => {
                setMiddleName(e.target.value);
                removeInputError("input-middle-name");
              }}
              error={inputErrors.includes("input-middle-name")}
            />
          </div>
        </section>

        <br />
        {/* {nameStep === 1 ? <ScrollableCard /> : (
           <section
           className="upload-image-address"
           style={{ display: nameStep === 2 ? "block" : "none" }}
         >
           <div className={`address-image-card`}>
             <ul className="list-group list-group-flush">
               <div className="address-card-title">{"อัพโหลดไฟล์"}</div>
               <hr className="line" />
               <li className="address-card-body">
                 <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                   <div>
                     <div className="bold">
                       {"รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน"}
                     </div>
                     <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                   </div>
                   <CustomInputFile
                     id={"file1"}
                     filename={fileName1}
                     onChange={(e) => {
                       const inputFile = e.target.files;
                       setFile1(inputFile);
                       setFileName1(inputFile[0].name);
                     }}
                   />
                 </div>
               </li>
               <li className="address-card-body">
                 <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                   <div>
                     <div className="bold">
                       {"สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)"}
                     </div>
                     <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                   </div>
                   <CustomInputFile
                     id={"file2"}
                     filename={fileName2}
                     onChange={(e) => {
                       const inputFile = e.target.files;
                       setFile2(inputFile);
                       setFileName2(inputFile[0].name);
                     }}
                   />
                 </div>
               </li>
               <li className="address-card-body">
                 <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                   <div>
                     <div className="bold">
                       {"สำเนาเอกสารการเปลี่ยนแปลงชื่อ-สกุล จากทางราชการ (ถ้ามี)"}
                     </div>
                     <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                   </div>
                   <CustomInputFile
                     id={"file3"}
                     filename={fileName3}
                     onChange={(e) => {
                       const inputFile = e.target.files;
                       setFile3(inputFile);
                       setFileName3(inputFile[0].name);
                     }}
                   />
                 </div>
               </li>
             </ul>
           </div>
         </section>
        )} */}
        <Box
          sx={{
            display: nameStep === 3 ? "flex" : "none",
            alignItems: "center",
          }}
          style={{ marginBottom: "10px" }}
        >
          <AssignmentTurnedInRoundedIcon
            fontSize={"large"}
            style={{ color: "rgb(255, 94, 67)" }}
          />
          <Typography style={{ fontSize: "20px", marginLeft: "10px" }}>
            ยืนยันข้อมูล
          </Typography>
        </Box>
        {nameStep == 1 ? null : nameStep == 3 ? (
          <ScrollableCardInformation />
        ) : (
          <section
            className="upload-image-address"
            style={{ display: nameStep === 2 ? "block" : "none" }}
          >
            <div className={`address-image-card`}>
              <ul className="list-group list-group-flush">
                <div className="address-card-title">{"อัพโหลดไฟล์"}</div>
                <hr className="line" />
                <FileUploadWithPreviewName
                  setFiles={setFiles}
                  fileDescriptions={fileDescriptions}
                  files={files}
                />
              </ul>
            </div>
          </section>
        )}

        {/* <section
          className="policy-address"
          style={{ display: nameStep === 1 ? "block" : "none" }}
          ref={refCarousel}
        >
          {nameData && nameData.length > 0 ? (
            <div>

              <Carousel
                emulateTouch={true}
                showThumbs={false}
                onChange={handleOwlChange}
                onClickItem={handleOwlChange}
                showArrows={false}
                showStatus={true}
                showIndicators={false}
                // className="p-lg-4"
                breakPoints={breakPoints}
                selectedItem={activeIndex}
                statusFormatter={(currentItem, total) => {
                  setCurrentItem(currentItem);
                  setTotalItem(total);
                }}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  setClickPre(() => clickHandler); // ตั้งค่า clickHandler ใน state

                  return null; // ไม่แสดงปุ่มลูกศรภายใน Carousel
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  setClickForward(() => clickHandler); // ตั้งค่า clickHandler ใน state

                  return null; // ไม่แสดงปุ่มลูกศรภายใน Carousel
                }}
              >
                {nameData.map((item, index) => (
                  <div className="frame-policy-new mt-3">
                    <ul
                      className="list-group list-group-flush"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                        }}
                      >
                        <h2
                          className="bold"
                          style={{
                            fontSize: "20px",
                            marginLeft: "20px",
                            alignItems: "start",
                            justifyItems: "start",
                            marginTop: "20px",
                          }}
                        >
                          {"กรมธรรม์เลขที่ : " + item.policy_no}
                        </h2>
                        <div className="d-flex flex-row">
                          <AssignmentIcon
                            style={{
                              cursor: "pointer",
                              marginLeft: "20px",
                              alignItems: "start",
                              justifyItems: "start",
                              marginTop: "20px",
                            }}
                            color="warning"
                            onClick={handleOpenModal}
                          />
                        </div>
                      </li>
                      <hr
                        style={{
                          margin: "0px 10px 15px 20px",
                          border: "solid 1px",
                          borderTop: 0.1,
                        }}
                      />
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">คำนำหน้าชื่อ</span>
                        <span className="bold">{`${item.title}`}</span>
                      </li>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">ชื่อ - นามสกุล</span>
                        <span className="bold">
                          {`${item.first_name} ${item.last_name}`}
                        </span>
                      </li>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">ชื่อกลาง</span>
                        <span className="bold">
                          {`${item.middle_name || "-"}`}
                        </span>
                      </li>
                    </ul>
                  </div>
                ))}
              </Carousel>
            </div>
          ) : (
            <div className="address-policy-card">
              {loading ? "กำลังโหลดข้อมูล..." : "ไม่พบข้อมูลกรมธรรม์"}
            </div>
          )}
        </section>
        {nameStep == 1 ? (
          <div
            className="d-flex flex-row justify-content-center gap-2"
            style={{ marginTop: 20 }}
          >
            <div
              onClick={clickPre}
              className="d-flex justify-content-center align-items-center pointer"
            >
              <ArrowBackIosIcon fontSize="small" color="warning" />
            </div>
            <div
              style={{
                backgroundColor: "rgba(255, 228, 224, 0.9)",
                height: 18,
                width: 18,
                fontSize: 10,
              }}
              className="rounded-3 text-secondary d-flex justify-content-center align-items-center"
            >
              {currentItem}
            </div>
            <div
              style={{ fontSize: 10 }}
              className="text-secondary d-flex justify-content-center align-items-center"
            >
              Of
            </div>
            <div
              style={{
                backgroundColor: "#F2F2F2",
                height: 18,
                width: 18,
                fontSize: 10,
              }}
              className="rounded-3 text-secondary d-flex justify-content-center align-items-center"
            >
              {totalItem}
            </div>
            <div
              onClick={clickForward}
              className="d-flex justify-content-center align-items-center pointer"
            >
              <ArrowForwardIosIcon fontSize="small" color="warning" />
            </div>
          </div>
        ) : (
          ""
        )} */}

        <br />

        <section className="action-address">
          <Grid
            container
            spacing={2}
            justifyContent="center" // จัดตำแหน่งตรงกลางแนวนอน
            alignItems="center" // จัดตรงกลางแนวตั้ง
            sx={{ minHeight: "10vh" }} // ให้ Grid เต็มความสูงหน้าจอ
          >
            <Grid item xs={12} sm={6} md={2}>
              <button
                onClick={() => {
                  if (nameStep === 1) {
                    // exit page or back step
                    navigate(-1);
                  } else if (nameStep === 2) {
                    setNameStep(1);
                  } else {
                    setNameStep(2);
                  }
                }}
                className="btn-address-back w-100 p-2 bold"
              >
                ย้อนกลับ
              </button>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <button
                style={{ fontSize: 18 }}
                onClick={() => {
                  if (nameStep === 1) {
                    let errorsName = validateName();
                    if (errorsName.length === 0) {
                      if (selectedName.length === 0) {
                        // setOpenErrorMissingName(true);
                        setToggleImg(false);
                        setTextModal(
                          "กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยนชื่อ - นามสกุล"
                        );
                        setCompletedModal(true);
                        refCarousel.current.scrollIntoView();
                      } else {
                        handleCheckAll();
                        setNameStep(2);
                      }
                    }
                  } else if (nameStep === 2) {
                    const errorImage = validateImage();

                    if (!errorImage) {
                      setNameStep(3);
                    }
                  } else {
                    saveData();
                  }
                }}
                className="btn-address-next w-100 p-2 bold"
              >
                {nameStep <= 2 ? "ต่อไป" : "บันทึก"}
              </button>
            </Grid>
          </Grid>
        </section>
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={handleCloseModal}
        contentLabel="ModalPolicy"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-lg-2 p-3">
          <h3 style={{ color: "#FA0E0E", fontSize: "18px" }}>กรมธรรม์เลขที่</h3>
          <div className="d-grid mb-4">
            <TextField
              type="text"
              size="small"
              onChange={(e) => handleSearchBasePolicy(e.target.value)}
              className=""
              value={searchBasePolicy}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} height={20} width={20} alt="" />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#FFF",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  height: "35px",
                  "& fieldset": {
                    borderColor: "#FB5E3F",
                  },
                  "&:hover fieldset": {
                    borderColor: "#FB5E3F",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FB5E3F",
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {!filteredOptions.length == 0 ? (
              filteredOptions.map(renderOptionRow)
            ) : (
              <div className="text-center text-muted">
                ไม่มีข้อมูลที่ตรงกับการค้นหา
              </div>
            )}
          </div>
          <Row className="d-flex flex-row justify-content-evenly mt-3">
            {/* <Col className="bg-danger"> */}
            <button
              style={{ fontSize: 15 }}
              onClick={handleConfrimSearch}
              className="btn-checkout-premium w-25 p-2 bold"
            >
              ตกลง
            </button>
            <button
              style={{ fontSize: 15 }}
              onClick={handleCloseModal}
              className="btn-checkout-back w-25 p-2 bold"
            >
              ยกเลิก
            </button>
          </Row>
        </div>
      </Modal>
      <BootstrapModal
        show={completedModal}
        backdrop="static"
        // size="md"
        // style={{}}
        className="backdrop-alert"
        contentClassName="modal-alert"
      >
        <BootstrapModal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={toggleImg ? greenCheck : remove}
              alt="check-icon"
              className="modal-image-size"
            />
          </div>

          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              xl={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                marginTop: 5,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="font-modal">{textModal}</div>
            </Col>
          </li>
          <div className="d-flex justify-content-center flex-wrap">
            <button
              className="btn-checkout-modal w-20 p-2 bold"
              onClick={() => {
                if (toggleImg) {
                  navigate("/personal");
                } else {
                  setCompletedModal(false);
                }
              }}
            >
              กลับ
            </button>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default EditName2;
