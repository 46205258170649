import { Axios } from "../../../util";
import axios from "axios";

const searchConsentHistoryAPI = async (policyNo,fromDate,toDate) => {
  try {
    const res = await Axios.get(
      `/api/policy/searchConsentHistory?policyNo=${policyNo}&fromDate=${fromDate}&toDate=${toDate}`,
    );
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const updateConsent = async (dataUpdate) => {
  // ดึง first_name และ last_name จาก localStorage
  const firstName = localStorage.getItem('first_name');
  const lastName = localStorage.getItem('last_name');

  try {
      const res = await Axios.post('/api/policy/updateStatusConsent', {
          status: dataUpdate,
          FirstName: firstName, // ใช้ค่าที่ดึงมาจาก localStorage
          LastName: lastName     // ใช้ค่าที่ดึงมาจาก localStorage
      }, {
          headers: {
              'Content-Type': 'application/json',
              'authorization': `Bearer ${localStorage.getItem('token_login')}`
          }
      });
      return res.data;
  } catch (error) {
      console.error("There was an error updating the taken data!", error);
      throw error;
  }
};

export { searchConsentHistoryAPI,
         updateConsent
 };
