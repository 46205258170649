import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, DialogContent } from "@mui/material";
import icon_check from "../../Icon//green-circle-check.png";

export const ModalAlert = (props) => {
  const { onClose, open, icon, title, actions } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={"xs"}
      fullWidth
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <DialogContent>
        <Box textAlign="center">
          {icon ? (
            icon
          ) : (
            <img
              src={icon_check}
              alt="check-icon"
              style={{ width: "150px", height: "150px" }}
            />
          )}
        </Box>

        <Box
          textAlign="center"
          sx={{ fontSize: "16px", fontWeight: "700", marginBlock: "12px" }}
        >
          {title}
        </Box>

        <Box textAlign="center">
          {actions ? (
            actions
          ) : (
            <button onClick={onClose} className="btn-fill-gray p-2 bold ml-2">
              ปิด
            </button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
