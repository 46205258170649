import Axios from "../util/Axios";

const getPolicyInformationAPI = async (insureIdCard) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(
      `/api/policy/policyInformation?insureIdCard=${insureIdCard}`
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getPolicyCouponAPI = async (policyNo) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(
      `/api/policy/policyCoupon?policyNo=${policyNo}`
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const verifyTokenAPI = async () => {
  try {
    const res = await Axios.get(`/VerifyToken`);
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getProfilePosAPI = async () => {
  try {
    const res = await Axios.get(
      `/profileStaff`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token_login"),
        },
      }
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export {
  getPolicyInformationAPI,
  getPolicyCouponAPI,
  verifyTokenAPI,
  getProfilePosAPI,
};
