import React, { useContext, useState, useEffect } from "react";
import { TextField } from "@mui/material";
import TransferContext, {
  TransferProvider,
} from "../TranferContextProvider.js";
import { verifyOtp } from "../quickTransferServices/QuickTransferServices.js";
import { notifySuccess, notifyWarning } from "../../../util/Notification.js";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Modal, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import InputAdornment from "@mui/material/InputAdornment";
import iconSearch from "../../../images/icon-search-2.png";
import remove from "../../../images/remove.png";
import { getParameterAPI } from "../../PolicyDetail/PolicyDetailService/policyDetailService";



const EnterOtp = () => {
  const {
    activeStep,
    setActiveStep,
    skipped,
    setSkipped,
    citizenId,
    setUuid,
    setCitizenId,
    mobileTel,
    setMobileTel
  } = useContext(TransferContext);

  const [otpValue, setOtpValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState("false");
  const [open, setOpen] = useState(false);
  const [textOtpAlertField, setTextOtpAlertField] = useState("");
  const [textAlertStatus, setTextAlertStatus] = useState(false);
  const [cusTel, setCusTel] = useState("");

  useEffect(() => {
    getTel();
  }, []);

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    const variable1 = cusTel.variable1;
    setCusTel(variable1);
  };



  const enterOtp = async () => {
    if (otpValue == "") {
      notifyWarning("กรุณากรอกหมายเลข OTP");
    } else {
      setLoading(true);
      const res = await verifyOtp(citizenId, otpValue);
      if (res.success == false) {
        if (res.payload.type == "otp_expire") {
          setLoading(false);
          setAlertText(res.payload.error);
          setOpen(true);
        } else {
          setLoading(false);
          setTextAlertStatus(true);
          setTextOtpAlertField(res.payload.error);
        }
      } else {
        setLoading(false);
        // setUuid(res.payload.profile);
        handleNext();
        notifySuccess("ยืนยันรหัส OTP สำเร็จ");
      }
      // console.log(res);
    }
  };
  const maskMobileTel = (mobileTel) => {
    if (mobileTel.length === 10) {
      return mobileTel.replace(/(\d{2})(\d{4})(\d{4})/, '$1xxxx$3');
    }
    return mobileTel; // ในกรณีที่หมายเลขไม่ใช่ 10 หลัก จะคืนค่าเดิม
  };
  const maskedMobileTel = maskMobileTel(mobileTel);


  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleConfrim = () => {
    setOpen(false);
    setCitizenId("");
    setActiveStep(0);
  };

  return (
    <>
      <div className="border-content">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
          }}
        >
          กรุณาใส่หมายเลข OTP 6 หลัก ที่ส่งไปยังเบอร์โทรศัพท์ของท่าน
          (รหัสนี้มีอายุการใช้งาน 5 นาที)
        </div>
        <TextField
          InputProps={{ sx: { marginTop: 2, borderRadius: 16, borderColor: '#fdcfc7' } }}
          id="bank_account_name"
          type="search"
          placeholder="OTP"
          variant="outlined"
          size="small"
          fullWidth
          value={otpValue}
          onChange={(e) => setOtpValue(e.target.value)}
          inputProps={{
            maxLength: 6,
            inputMode: "numeric",
            pattern: "[0-9]*",
            onInput: (e) => {
              e.target.value = e.target.value.replace(
                /[^0-9]/g,
                ""
              );
            },
          }}
        />

        {textAlertStatus ? (
          <div
            className="font-size-content-otp"
            style={{
              marginLeft: 10,
              marginTop: 5,
              color: "red",
            }}
          >
            {textOtpAlertField}
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            marginTop: 10,
          }}
        >
          <div className="font-size-content-otp">
            ส่งรหัสผ่านไปที่หมายเลข {maskedMobileTel} เพื่อเข้าสู่ระบบ
          </div>
          <div className="font-size-content-otp">
            {" "}
            กรณีไม่ได้รับหรือเปลี่ยนหมายเลขโทรศัพท์มือถือ
          </div>
          <div className="font-size-content-otp">
            กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์ {cusTel}
          </div>
        </div>
      </div>
      <button
        style={{ fontSize: 15 }}
        onClick={enterOtp}
        className="btn-checkout-premium w-100 p-2 bold"
      >
        ตกลง
      </button>
      <button
        className="btn-checkout-back w-100 p-2 bold"
        onClick={handleBack}
      >
        กลับ
      </button>
      <Modal show={open} backdrop="static" size="md" style={{}}>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={remove}
              alt={"confirmGif"}
              style={{
                height: "auto",
                width: "20%",
              }}
            />
          </div>

          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              xl={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                marginTop: 20,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h3 className="font-size-content-alert-modal">{alertText}</h3>
            </Col>
          </li>
          <div className="App">
            <div className="d-flex justify-content-center flex-wrap">
              <button
                className="btn-checkout-back-modal w-30 p-2 bold"
                onClick={handleConfrim}
              >
                กลับ
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnterOtp;
