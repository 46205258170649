import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Card from '@mui/material/Card';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
const ErrorPage = () => {
  const handleBackToHome = () => {
    window.location.href = "/home"; // กลับไปหน้าแรก
  };

  return (

    <Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh"
  textAlign="center"
//   sx={{ backgroundColor: "#F5F5F5" }} // เพิ่มพื้นหลังสีอ่อน
>
  <Card
    variant="outlined"
    sx={{
      maxWidth: 600, // กำหนดความกว้างสูงสุดของ Card
      width: "100%", // ให้ปรับขนาดตามหน้าจอ แต่ไม่เกิน maxWidth
      padding: 5, // เพิ่ม padding ให้เนื้อหาด้านใน
      borderRadius: "14px", // โค้งมนมุม
    //   boxShadow: 1, // เพิ่มเงา
    borderColor:"white",
   
    backgroundColor: "rgba(245, 245, 245, 0.5)", // สีโปร่งใส 50%
    }}
  >
       <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={2}
      >
        <ErrorOutlineIcon
          sx={{
            fontSize: 50,
            color: "#FF6B6B",
          }}
        />
      </Box>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
     
    >
      <Typography
        variant="h5"
        component="h1"
        gutterBottom
        sx={{ color: "black", fontWeight: "bold" }}
      >
        แรบบิท ไลฟ์
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        ไม่พบหน้าที่คุณต้องการใช้งาน
      </Typography>
      <Button
        variant="contained"
        onClick={handleBackToHome}
        sx={{
          backgroundColor: "#FF6B6B",
          color: "#fff",
          marginTop: "20px",
          ":hover": { backgroundColor: "#FF4A4A" },
        }}
      >
        กลับไปหน้าหลัก
      </Button>
    </Box>
  </Card>
</Box>

  );
};

export default ErrorPage;
