import axios from "axios";


const token = localStorage.getItem('token_login') ? localStorage.getItem('token_login') : "";
// const token = localStorage.getItem('token_login') ? "Bearer " + localStorage.getItem('token_login') : "";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  // headers: { "Content-Type": "application/json", authorization: token },
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : '', // เช็คว่ามี token หรือไม่
  },
});

export default Axios;
