import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import { StoreContext } from "../../../context/store";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { notifyWarning } from "../../../util/Notification";

const ShowBankInformation = ({ channelType }) => {
  const {
    informationEditBank,
    updateInformationEditBank,
    informationEditPromptPayAccount,
    updateInformationEditPromptPay,
  } = useContext(StoreContext);

  const handleChange = (key, value) => {
    // console.log(key, value);
    let data = {
      [key]: value,
    };
    if (channelType === "promptpay") {
      updateInformationEditPromptPay(data);
    } else {
      updateInformationEditBank(data);
    }
  };

  return (
    <>
      {channelType === "bank" ? (
        <>
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              // xl={3}
              // md={3}
              // xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span className="bold">ชื่อบัญชี : </span>
            </Col>
            <Col
              // xl={9}
              // md={9}
              // xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-center",
                alignItems: "center",
              }}
            >
              <span className="light">
                {informationEditBank.bank_account_name}
              </span>
            </Col>
          </li>
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              // xl={3}
              // md={3}
              // xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span className="bold">ธนาคาร : </span>
            </Col>
            <Col
              // xl={9}
              // md={9}
              // xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="light">{informationEditBank.bank_name}</span>
            </Col>
          </li>
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              // xl={3}
              // md={3}
              // xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span className="bold">เลขที่บัญชี : </span>
            </Col>
            <Col
              // xl={9}
              // md={9}
              // xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="light">{informationEditBank.bank_account}</span>
            </Col>
          </li>
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              // xl={3}
              // md={3}
              // xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span className="bold">วันที่ทำรายการ : </span>
            </Col>
            <Col
              // xl={9}
              // md={9}
              // xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="light">{moment().add(543, 'years').format("DD/MM/YYYY")}</span>
            </Col>
          </li>
          <Col
            // xl={3}
            // md={3}
            // xs={5}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "start",
              marginTop: 20,
            }}
          >
            <Checkbox
              // checked={
              //   informationEditBank.bank_account_flag_information
              // }
              style={{marginTop:"-10px"}}

              checked={informationEditBank.bank_account_flag_information}
              onChange={(e) => {
                handleChange("bank_account_flag_information", e.target.checked);
              }}
            // inputProps={{ "aria-label": "controlled" }}
            />
            <p>
              ข้าพเจ้าขอรับรองว่าข้อมูลดังกล่าวเป็นจริงทุกประการ
              และได้อ่านเงื่อนไขการบริการเป็นที่เข้าใจเรียบร้อยแล้ว
            </p>
          </Col>
        </>
      ) : (
        <>
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              // xl={3}
              // md={3}
              // xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="bold">โอนเงินเข้าบัญชี : </span>
            </Col>
            <Col
              // xl={9}
              // md={9}
              // xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="light">พร้อมเพย์</span>
            </Col>
          </li>
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              // xl={3}
              // md={3}
              // xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="bold">เลขประจำตัวประชาขน : </span>
            </Col>
            <Col
              // xl={9}
              // md={9}
              // xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="light">
                {informationEditPromptPayAccount.prompt_pay_account}
              </span>
            </Col>
          </li>
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              // xl={3}
              // md={3}
              // xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="bold">วันที่ทำรายการ : </span>
            </Col>
            <Col
              // xl={9}
              // md={9}
              // xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span className="light">{moment().add(543, 'years').format("DD/MM/YYYY")}</span>
            </Col>
          </li>
          <Col
            // xl={3}
            // md={3}
            // xs={5}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "start",
              marginTop: 20,
            }}
          >
            <Checkbox
              checked={
                informationEditPromptPayAccount.prompt_pay_flag_information
              }
              style={{ marginTop: "-10px" }}

              onChange={(e) => {
                handleChange("prompt_pay_flag_information", e.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}

            />
            <p>
              ข้าพเจ้าขอรับรองว่าข้อมูลดังกล่าวเป็นจริงทุกประการ
              และได้อ่านเงื่อนไขการบริการเป็นที่เข้าใจเรียบร้อยแล้ว
            </p>
          </Col>
        </>
      )}
    </>
  );
};

export default ShowBankInformation;
