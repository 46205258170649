import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/Login/Login";
import Registration from "./views/Login/Registration";
import OTP from "./views/Login/otp";
import OTP_LOGIN from "./views/Login/otp_login";
import Main from "./views/Login/Main";
import Home from "./views/Home/Home";
import ListPolicy from "./views/Policy/list_policy";
import Download from "./views/Download/Download";
import Personal from "./views/Personal/personal";
import EditEmail from "./views/Personal/edit_email";
import EditName from "./views/Personal/edit_name";
import EditCard from "./views/Personal/edit_card";
import EditPhone from "./views/Personal/edit_phone";
import EditAddress from "./views/Personal/edit_address";
import EditAddress2 from "./views/Personal/edit_address_v2.js";
import Request from "./views/Request/request";
import Request_loan from "./views/Request/request_loan";
// import Request_freelook from "./views/Request/request_freelook";
// import Request_RPU from "./views/Request/request_RPU";
// import Request_ETI from "./views/Request/request_ETI";
import Request_RD from "./views/Request/request_RD";
import Request_RD_comp from "./views/Request/request_RD_comp";
import Request_freelook_comp from "./views/Request/request_freelook_comp";
import Verify from "./views/Verify/verify";
import Download_doc from "./views/Download/Download_doc";
import Update_request from "./views/UpdateRequest/Update_request";
import Report from "./views/Report/Report";
import Download_form from "./views/Download/Download_form";
import PolicyDetail from "./views/PolicyDetail/policyDetail";
import ExemptIncomeTax from "./views/ExemptIncomeTax/exemptIncomeTax";
import LetterManage from "./views/LetterManage/letterManage";
import LetterDigiCom from "./views/LetterDigiCom/letterDigiCom";
import RequestReport from "./views/RequestReport/requestReport";

import LetterDigiComDetail from "./views/LetterDigiCom/letterDigiComDetail";
import ReportRequest from "./views/ReportRequest/reportRequest.js";
import PolicyList from "./views/PolicyList/policyList";
import PayInsure from "./views/PayInsure/payInsure";
import RefundPolicy from "./views/RefundPolicy/refundPolicy";
import SendDoc from "./views/RefundPolicy/sendDoc";
import BrowseFile from "./views/RefundPolicy/browseFile";
import ChannelPay from "./views/RefundPolicy/channelPay";
import EditBank from "./views/RefundPolicy/editBank.js";
import AddChannelPay from "./views/RefundPolicy/addChannelPay";
import LoginStaff from "./views/Login/LoginStaff";
import Benefit from "./views/Benefit/benefit";
import EditBenefit from "./views/Benefit/editBenefit";
import DownloadDoc from "./views/DownloadDoc/downloadDoc";
import AddParameter from "./views/Parameter/Add_Parameter.js";
import AddParameterEdit from "./views/Parameter/Add_Parameter_edit.js";
import PaymentCallBack from "./views/Payment/PaymentCallBack/paymentCallBack.js";
import Payment from "./views/Payment/payment.js";
import ConsentHistory from "./views/ConsentHistory/consentHistory.js";
import PaymentInquiry from "./views/PaymentInquiry/paymentInquiry.js";
import PrivateRoute from "./PrivateRoute.js";
import Footer from "./views/Component/Footer.js";
import Footer_Home from "./views/Component/Footer_home.js";
import { motion } from "framer-motion";
import logoLine from "./images/19201080-forNoteBookpcNBG.png";
// import logoLine from "./images/rabbit_model.png";
import logoMascot from "./images/10241366IPADTablet.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import ChangePay from "./views/changePay/changePay.js";
import ChangeChannel from "./views/changePay/changeChannel.js";
import PrivateRoutePos from "./PrivateRoutePos.js";
import EditPhone2 from "./views/Personal/edit_phone_v2.js";
import EditEmail2 from "./views/Personal/edit_email_v2.js";
import EditName2 from "./views/Personal/edit_name_v2.js";
import EditCard2 from "./views/Personal/edit_card_v2.js";
import { BenefitReceivingHistory } from "./views/BenefitReceivingHistory/BenefitReceivingHistory.js";
import { MailManagement } from "./views/MailManagement/MailManagement.js";
import { BenefitReceivingDetail } from "./views/BenefitReceivingHistory/BenefitReceivingDetail.js";
import PolicyLoan from "./views/PolicyLoan/policyLoan.js";
import WrappedQuickTransfer from "./views/QuickTransfer/quickTransfer.js";
import RequestV2 from "./views/RequestV2/requestV2.js";
import LetterMenu from "./views/RequestV2/LetterMenu.js";

import Request_freelook from "./views/RequestManage/requestFreelook";
import Request_RPU from "./views/RequestManage/requestRpu";
import Request_ETI from "./views/RequestManage/requestEti";

import logoLeft from "./images/ddddd.png";
import logoRight from "./images/ccccc.png";
import logo11 from "./images/11111.png";
import logo22 from "./images/22222.png";
import ErrorPage from "./Error/ErrorPage.js";

const noFooterRoutes = ["/", "/login"];



const App = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <div className="layout-new" style={{minHeight:"100vh"}}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/login" element={<LoginStaff />} />
            <Route path="/home" element={<Home />} />

            <Route path="/mail-management" element={<MailManagement />} />

            <Route path="/rabbitpay" element={<WrappedQuickTransfer />} />
            <Route path="*" element={<ErrorPage />} />
            <Route exact element={<PrivateRoute />}>
              <Route path="/policy" element={<ListPolicy />} />
              <Route path="/download" element={<Download />} />
              <Route path="/download/document" element={<Download_doc />} />
              <Route path="/download/form" element={<Download_form />} />
              <Route path="/personal" element={<Personal />} />
              {/* <Route path="/personal/email" element={<EditEmail />} /> */}
              <Route path="/personal/email" element={<EditEmail2 />} />
              {/* <Route path="/personal/location" element={<EditAddress />} /> */}
              <Route path="/personal/location" element={<EditAddress2 />} />
              {/* <Route path="/personal/card" element={<EditCard />} /> */}
              <Route path="/personal/card" element={<EditCard2 />} />
              {/* <Route path="/personal/name" element={<EditName />} /> */}
              <Route path="/personal/name" element={<EditName2 />} />
              {/* <Route path="/personal/phone" element={<EditPhone />} /> */}
              <Route path="/personal/phone" element={<EditPhone2 />} />
              <Route path="/request" element={<RequestV2 />} />

              {/* <Route path="/requestv2" element={<RequestV2 />} /> */}
              <Route path="/request/loan" element={<Request_loan />} />
              {/* <Route path="/request/freelook" element={<Request_freelook />} /> */}
              <Route
                path="/request/freelook/complete"
                element={<Request_freelook_comp />}
              />
              <Route path="/request/eti" element={<Request_ETI />} />
              {/* <Route path="/request/rpu" element={<Request_RPU />} /> */}
              <Route
                path="/request/rd/complete"
                element={<Request_RD_comp />}
              />
              <Route path="/verify" element={<Verify />} />

              <Route path="/report" element={<Report />} />
              <Route path="/policyDetail" element={<PolicyDetail />} />
              <Route path="/exemptIncomeTax" element={<ExemptIncomeTax />} />
              <Route path="/policylist" element={<PolicyList />} />
              <Route path="/payInsure" element={<PayInsure />} />
              <Route path="/refundPolicy" element={<RefundPolicy />} />
              <Route path="/sendDoc" element={<SendDoc />} />
              <Route path="/browseFile" element={<BrowseFile />} />
              <Route path="/channelPay" element={<ChannelPay />} />
              <Route path="/editBank" element={<EditBank />} />
              <Route path="/addChannelPay" element={<AddChannelPay />} />
              <Route path="/benefit" element={<Benefit />} />
              <Route path="/editBenefit" element={<EditBenefit />} />
              <Route path="/downloadDoc" element={<DownloadDoc />} />
              <Route path="/addParameter" element={<AddParameter />} />
              <Route path="/addParameterEdit" element={<AddParameterEdit />} />
              <Route path="/PaymentCallBack" element={<PaymentCallBack />} />
              <Route path="/Payment" element={<Payment />} />

              <Route path="/ChangePay" element={<ChangePay />} />
              <Route path="/ChangeChannel" element={<ChangeChannel />} />
              <Route path="/PolicyLoan" element={<PolicyLoan />} />
              {/* <Route path="/rabbitpay" element={<WrappedQuickTransfer />} /> */}
              <Route path="/request/freelook" element={<Request_freelook />} />
              <Route path="/request/rpu" element={<Request_RPU />} />

            </Route>
            <Route exact element={<PrivateRoutePos />}>
              <Route path="/update_request" element={<Update_request />} />
              <Route path="/PaymentInquiry" element={<PaymentInquiry />} />
              <Route path="/RequestReport" element={<RequestReport />} />
              <Route path="/ConsentHistory" element={<ConsentHistory />} />
              <Route path="/letter" element={<LetterMenu />} />

              <Route path="/letterManage" element={<LetterManage />} />
              <Route path="/letterDigiCom" element={<LetterDigiCom />} />
              <Route
                path="/letterDigiComDetail"
                element={<LetterDigiComDetail />}
              />
            </Route>
            <Route
              path="/benefit-receiving-history"
              element={<BenefitReceivingHistory />}
            />
            <Route
              path="/benefit-receiving-detail"
              element={<BenefitReceivingDetail />}
            />
            <Route
              path="/reportRequest"
              element={<ReportRequest />}
            />
          </Routes>
        </BrowserRouter>
        <div style={{ width: '100%', height: '100%' }}>
          <motion.div
            className="img-motion-left2"
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              display: isSmallScreen ? "none" : "block",

            }}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1,
              delay: 0.1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <img src={logoLeft} className="size-img2" alt="Logo Left" />
          </motion.div>

          <motion.div
            className="img-motion-right2"
            style={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              display: isSmallScreen ? "none" : "block",
            }}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <img src={logoRight} className="size-img2" alt="Logo Right" />
          </motion.div>
          <motion.div
            // className="box"
            style={{ display: isSmallScreen ? "block" : "none" }}
            className="img-motion-left"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <img src={logoMascot} className="size-img-right" alt="" />
          </motion.div>
        </div>
      </div>
      {/* <Footer_Home /> */}
      {/* Show Footer_Home only if the current path is not in noFooterRoutes */}
      {!noFooterRoutes.includes(location.pathname) && <Footer_Home />}
    </>
  );
};

export default App;
