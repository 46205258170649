import { Axios } from "../../../util";

const getPolicyCouponAPI = async (policyNo) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(
      `/api/policy/policyCoupon?policyNo=${policyNo}`
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const uploadFileAPI = async (formData) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(`/api/policy/upload`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const requestOtpAPI = async () => {
  try {
    const res = await Axios.post(
      `/requestOTP`,
      {},
      {
        headers: { accept: "application/json" },
      }
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const verifyChangeBankAPI = async (data) => {
  try {
    const formData = new FormData();
    formData.append("bank_account_file", data.bank_account_file[0]);
    formData.append("bank_account", data.bank_account);
    formData.append("bank_account_file_name", data.bank_account_file_name);
    formData.append(
      "bank_account_flag_information",
      data.bank_account_flag_information
    );
    formData.append("bank_account_name", data.bank_account_name);
    formData.append("bank_name", data.bank_name);
    formData.append("otp", data.otp);
    formData.append("policy", data.policy);
    const res = await Axios.post(`/verifyOTP`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        accept: "application/json",
      },
    });
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const verifyChangePromptPayAPI = async (data) => {
  try {
    const res = await Axios.post(
      `/verifyOTPPromptPay`,
      {
        otp: data.otp,
        policy: data.policy,
        prompt_pay_account_name: data.prompt_pay_account_name,
        prompt_pay_account: data.prompt_pay_account,
        prompt_pay_flag_information: data.prompt_pay_flag_information
      },
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getInfomationAPI = async () => {
  try {
    const res = await Axios.get(
      `api/policy/getInformationNotPolicies`,
      {
        headers: { accept: "application/json" },
      }
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export {
  getPolicyCouponAPI,
  uploadFileAPI,
  requestOtpAPI,
  verifyChangeBankAPI,
  verifyChangePromptPayAPI,
  getInfomationAPI,
};
