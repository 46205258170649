import { json } from "react-router-dom";
import { Axios } from "../../../util";
import axios from "axios";

const getPolicyInformationAPI = async (insureIdCard) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(
      `/api/policy/policyInformation?insureIdCard=${insureIdCard}`,
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const insertConsentAPI = async (json) => {
  try {
    const res = await Axios.post(
      `/api/policy/insertHisLogConsent`,
      json,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error inserting the consent data!", error);
    throw error;
  }
};


export { getPolicyInformationAPI,
         insertConsentAPI
 };
