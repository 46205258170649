import { json } from "react-router-dom";
import { Axios } from "../../../util";
import axios from "axios";

const searchDigitalCom = async (searchBy,param) => {
  try {
    const res = await Axios.post(`/api/policy/searchDigitalCom?searchBy=${searchBy}&param=${param}`,
    );
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const deleteDigitalCom = async (id) => {
  try {
    const res = await Axios.delete(`/api/policy/deleteDigitalCom?id=${id}`);
    return res.data;
  } catch (error) {
    console.error("There was an error deleting the data!", error);
    throw error;
  }
};

const updateDigitalCom = async (id, updateData) => {
  try {
    const res = await Axios.post(`/api/policy/updateDigitalCom?id=${id}`, updateData);
    return res.data;
  } catch (error) {
    console.error("There was an error updating the data!", error);
    throw error;
  }
}

const policyLoanEnquiry = async (policyNo) => {
  try {
    const res = await Axios.post(`/api/policy/policyLoanEnquiry?policyNo=${policyNo}`)
    return res.data;
  } catch (error) {
    console.error("There was an error updating the data!", error);
    throw error;

  }

}

const policyLoanHistory = async (policyNo) => {
  try {
    const res = await Axios.post(`/api/policy/policyLoanHistory?policyNo=${policyNo}`)
    return res.data;
  } catch (error) {
    console.error("There was an error updating the data!", error);
    throw error;

  }

}



export { searchDigitalCom,
         deleteDigitalCom,
         updateDigitalCom,
         policyLoanEnquiry,
         policyLoanHistory
 };
