import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { Col } from "react-bootstrap";
import { CurrencyFormater } from "../../util";
import "./refundPolicy-styles.css";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow
        className={classes.tableRow}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell
          className="font-new"
          align="center"
          component="th"
          scope="row"
          width={100}
        >
          {row.couponYear}
        </TableCell>
        <TableCell className="font-new" align="center">
          {row.couponDueDate}
        </TableCell>
        <TableCell className="font-new" align="center" width={100}>
          {CurrencyFormater(row.couponPaymentAmount)}
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="bold">ประเภทเงิน : </span>
                <span className="light">{row.couponType}</span>
              </li>
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="bold">วันที่รับเงิน : </span>
                <span className="light">{row.couponPaymentDate}</span>
              </li>
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="bold">ช่องทางการรับเงิน : </span>
                <span className="light">{row.couponPaymentType}</span>
              </li>
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="bold">ธนาคาร : </span>
                <span className="light">
                  {row.couponBankName ? row.couponBankName : "-"}
                </span>
              </li>
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="bold">เลขบัญชี / เลขที่เช็ค : </span>
                <span className="light">
                  {row.couponBankAccount ? row.couponBankAccount : "-"}
                </span>
              </li>
              {/* <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col
                  // xl={3}
                  // md={3}
                  // xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <span className="bold">วันที่รับเงิน : </span>
                </Col>
                <Col
                  // xl={8}
                  // md={8}
                  // xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <span className="light">{row.couponPaymentDate}</span>
                </Col>
              </li>
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col
                  // xl={3}
                  // md={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <span className="bold">ช่องทางการรับเงิน :</span>
                </Col>
                <Col
                  // xl={8}
                  // md={8}
                  // xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <span className="light">{row.couponPaymentType}</span>
                </Col>
              </li>
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col
                  // xl={3}
                  // md={3}
                  // xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <span className="bold">ธนาคาร : </span>
                </Col>
                <Col
                  // xl={8}
                  // md={8}
                  // xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <span className="light">{row.couponBankName ? (row.couponBankName) : "-"}</span>
                </Col>
              </li>
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col
                  // xl={3}
                  // md={3}
                  // xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <span className="bold">เลขบัญชี : </span>
                </Col>
                <Col
                  // xl={8}
                  // md={8}
                  // xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <span className="light">{row.couponBankAccount}</span>
                </Col>
              </li> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const theme = createMuiTheme({
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: "#FFA500", // กำหนดสีของ header column เป็นสีส้ม
      },
    },
  },
});
const useStyles = makeStyles({
  tableCell: {
    padding: "5px",
  },
  tableRow: {
    "& > .MuiTableCell-root": {
      padding: "5px",
    },
  },
});

export default function CollapsibleTable(props) {
  const classes = useStyles();
  const { data } = props;

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow
              className={classes.tableRow}
              style={{ backgroundColor: "#e7e7e786" }}
            >
              <TableCell className="font-new" align="center">
                ปีที่
              </TableCell>
              <TableCell className="font-new" align="center">
                วันที่ครบกำหนด
              </TableCell>
              <TableCell className="font-new" align="center">
                จำนวนเงิน
              </TableCell>
              <TableCell
                align="center"
                style={{ minWidth: 10, padding: "1px" }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.couponHistory.map((item, idx) => (
              <Row key={idx} row={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}
